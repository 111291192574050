import { FC } from 'react'
// Hooks
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
// Components
import { Root } from './Upload.styled'
// Utils
import { convertBytesToHumanReadable } from 'modules/core/utils'
// Types
import { UploadProps } from './Upload.interface'

export const Upload: FC<UploadProps> = props => {
  const { t } = useTranslation('components', { keyPrefix: 'upload' })

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone(props)
  const { onClick, ...otherProps } = getRootProps()

  const isFileSelected = acceptedFiles.length !== 0
  const [file] = acceptedFiles

  return (
    <Root {...otherProps} onClick={onClick}>
      <input {...getInputProps()} />
      {!isFileSelected
        ? t('noFile')
        : `${file.name} - ${convertBytesToHumanReadable(file.size)}`}
    </Root>
  )
}

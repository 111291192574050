import { useCallback } from 'react'
import { useObservable } from 'modules/core/hooks'
import { useUser } from './useUser'
import { TokenService } from 'services/Token.service'
import { doesUserHaveRole, isUserAllowedFeatureTier } from '../utils'
import { UserFeatureTier, UserRole } from '../types'

type ValidateUserAccessOpts = {
  featureTiers?: UserFeatureTier[]
  roles?: UserRole[]
}

export const useUserAccess = () => {
  const token = useObservable(TokenService.getInstance().getToken())
  const isAuthenticated = token !== null

  const { data: user } = useUser({ enabled: isAuthenticated, retry: false })

  const validateUserAccess = useCallback(
    ({ featureTiers = [], roles = [] }: ValidateUserAccessOpts) => {
      if (featureTiers.length && !isUserAllowedFeatureTier(user, featureTiers))
        return false

      if (roles.length && !doesUserHaveRole(user, roles)) return false

      return true
    },
    [user]
  )

  return {
    featureTier: user?.feature_tier,
    role: user?.role,
    validateUserAccess,
  }
}

import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
// Context
import { ComponentInfoContext } from 'modules/generic/context'
import {
  defaultIconName,
  eventIndexToIconDictionary,
} from 'modules/core/constants'
import { VideoPlayerContext } from 'modules/video-player/context'
// Redux
import { selectMediaLocator } from 'modules/video-player/redux'
// Hooks
import {
  useReduxSelector,
  useServerTranslatedProperty,
  useSnackbar,
} from 'modules/core/hooks'
import { useIsUserAccessGroupMember, useUserAccess } from 'modules/user/hooks'
import { useOrganizationContext } from 'modules/organization/hooks'
import { useMatch, useMatchHighlights } from 'modules/match/hooks'
import { useHighlightCreate, useHighlightDelete } from 'modules/highlight/hooks'
import {
  useAnalyserPlayer,
  useAnalyserPlayerTimestamp,
} from 'modules/analyser/hooks'
import { useEventList } from 'modules/event/hooks'
import { useAssetCreate } from 'modules/asset/hooks'
import {
  usePlaylistAssetCreate,
  usePlaylistCreate,
} from 'modules/playlist/hooks'
// Components
import {
  ActionButton,
  Icon,
  Menu,
  MenuItem,
  ResourceControls,
  ToggleSwitch,
} from 'modules/core/components'
import { ToolbarControls, ToolbarHeader } from 'modules/generic/components'
import { ToolbarDivider } from 'modules/generic/styled'
import { OptionsSelectorMenuItem } from 'modules/core/styled'
import { VideoPlayerSelector } from 'modules/video-player/components'
import { LayoutItemToolbarContainer } from 'modules/layout/components/LayoutItemToolbar/LayoutItemToolbar.styled'
import { TransferResourceModal } from 'modules/resource/components'
import { HighlightUpdateModal } from 'modules/highlight/components'
// Utils
import { convertSecondsToTime } from 'modules/core/utils'
// Constants
import {
  OMITTED_HIGHLIGHT_EVENT_TYPES,
  HIGHLIGHT_COLORS,
  OFFICIAL_HIGHLIGHT_CREATION_ACCESS_GROUPS,
} from 'modules/highlight/constants'
// Types
import {
  Highlight as IHighlight,
  Organization,
} from '@sporttotal-tv/dip-coaching-client'

import {
  Root,
  ToolbarContainer,
  Divider,
  Tab,
  HighlightButtonGrid,
  StyledButton,
  ActionText,
  Content,
  Label,
  InputText,
  ContentToolbarItem,
  ContentToolbar,
  HighlightsList,
  HighlightWrapper,
  HighlightMeta,
  HightlightIconContainer,
  HighlightTextPrimaryContainer,
  HighlightTextSecondaryContainer,
  HighlightTitle,
  HighlightTimestamp,
  HighlightDescription,
  HighlightTextContainer,
  HighlightOptionsSelector,
} from './Highlight.styled'

export const Highlight = () => {
  const playerTimestamp = useAnalyserPlayerTimestamp()
  const player = useAnalyserPlayer()
  const { componentId, layoutIndex } = useContext(ComponentInfoContext)

  const { validateUserAccess } = useUserAccess()

  const tServer = useServerTranslatedProperty()
  const { t } = useTranslation('components', { keyPrefix: 'highlight' })
  const { t: tPlayerControls } = useTranslation('components', {
    keyPrefix: 'playerControls',
  })
  const { t: tAsset } = useTranslation('components', {
    keyPrefix: 'asset',
  })

  const [activeView, setActiveView] = useState<'add' | 'view'>('add')
  const [delay, setDelay] = useState(3)
  const [isOfficial, setIsOfficial] = useState(false)

  const { activeOrganizationId, organizations } = useOrganizationContext()
  const { highlights } = useMatchHighlights({ is_official: isOfficial })
  const { match } = useMatch()
  const eventList = useEventList({ sport_id: match?.sport?.id })

  const { playerId } = useContext(VideoPlayerContext)
  const mediaLocator = useReduxSelector(state =>
    selectMediaLocator(state, playerId ?? '')
  )

  const highlightCreate = useHighlightCreate()
  const highlightDelete = useHighlightDelete()
  const assetCreate = useAssetCreate()

  const playlistCreate = usePlaylistCreate()
  const playlistAssetCreate = usePlaylistAssetCreate()

  const canCreateOfficialTicker = useIsUserAccessGroupMember(
    OFFICIAL_HIGHLIGHT_CREATION_ACCESS_GROUPS
  )

  const showSnackbar = useSnackbar()
  const [transferModalAnchor, setTransferModalAnchor] = useState<HTMLElement>()

  // as one?
  const [selectedTicker, setSelectedTicker] = useState<IHighlight>()
  const [editingHighlight, setEditingHighlight] = useState<IHighlight>()

  const canCreatePlaylist = validateUserAccess({
    featureTiers: ['basic', 'advanced', 'professional'],
  })

  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>()

  const sortedHighlights = useMemo(
    () => highlights.sort((a, b) => (a.start_time > b.start_time ? 1 : -1)),
    [highlights]
  )

  const canTransfer = useMemo(
    () => organizations.length && !activeOrganizationId && !isOfficial,
    [activeOrganizationId, organizations.length, isOfficial]
  )

  const addManualHighlight = (eventId: string) => () => {
    if (match) {
      highlightCreate.mutate({
        accuracy: 1,
        start_time: playerTimestamp - delay,
        duration: 4,
        event_id: eventId,
        match_id: match.id,
        is_manual: true,
        is_official: canCreateOfficialTicker ? isOfficial : false,
      })
    }
  }

  const handleHighlightDelete = (tickerId: string) => () =>
    highlightDelete.mutate(tickerId)

  const handleDelayChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setDelay(Number(event.target.value)),
    []
  )

  const handleEventClick = useCallback(
    (time: number) => () => {
      if (player?.currentTime) player.currentTime = time
    },
    [player]
  )

  const handleTransferOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>, highlight: IHighlight) => {
      setTransferModalAnchor(event.target as HTMLElement)
      setSelectedTicker(highlight)
    },
    []
  )

  const handleTransferClose = useCallback(() => {
    setTransferModalAnchor(undefined)
    setSelectedOrganization(undefined)
  }, [])

  const handleEditingHighlightModal = useCallback(
    () => setEditingHighlight(undefined),
    []
  )

  const officialToggleSwitch = (
    <ToggleSwitch
      checked={isOfficial}
      onChange={() => setIsOfficial(!isOfficial)}
    />
  )

  const handleCreateHighlightClip = async (highlight: IHighlight) => {
    if (!match || !mediaLocator) return

    const eventName = tServer(highlight.event?.name)
    const tags = []

    if (eventName) tags.push(eventName)

    const activeMatchAsset = match.match_assets?.find(
      asset => asset.media_locator.id === mediaLocator.id
    )

    if (!activeMatchAsset) return

    return assetCreate.mutateAsync(
      {
        title: `${eventName} - ${convertSecondsToTime(highlight.start_time)}`,
        start_time: highlight.start_time,
        duration: highlight.duration,
        event_id: highlight.event_id,
        description: highlight.description || undefined,
        match_asset_id: activeMatchAsset.id,
        tags,
      },
      {
        onSuccess: () =>
          showSnackbar({
            type: 'success',
            message: tAsset('notification.clipCreatedRequestCompleted'),
          }),
      }
    )
  }

  const handleCreateHighlightsPlaylist = async () => {
    const highlightsType = isOfficial
      ? tPlayerControls('officialTicker')
      : tPlayerControls('userTicker')

    const allHighlights = (await Promise.all(
      highlights.map(handleCreateHighlightClip)
    )) as unknown as IHighlight[]

    const playlist = await playlistCreate.mutateAsync({
      title: match?.title ?? '',
      description: highlightsType,
      public: false,
    })

    playlistAssetCreate.mutate({
      playlist_id: playlist.id,
      asset_ids: allHighlights.map(({ id }) => id),
    })
  }

  return (
    <>
      <Root>
        <LayoutItemToolbarContainer>
          <ToolbarHeader layoutId={componentId} layoutIndex={layoutIndex}>
            <ToolbarDivider />
            <VideoPlayerSelector />
          </ToolbarHeader>
          <ToolbarContainer>
            <Tab
              active={activeView === 'add'}
              onClick={() => setActiveView('add')}
            >
              Add
            </Tab>
            <Divider />
            <Tab
              active={activeView === 'view'}
              onClick={() => setActiveView('view')}
            >
              View
            </Tab>
          </ToolbarContainer>
          <ToolbarControls layoutId={componentId} layoutIndex={layoutIndex} />
        </LayoutItemToolbarContainer>

        <Content>
          {activeView === 'add' && (
            <div>
              <ContentToolbar>
                {canCreateOfficialTicker && (
                  <ContentToolbarItem>
                    Official
                    {officialToggleSwitch}
                  </ContentToolbarItem>
                )}
                <ContentToolbarItem>
                  <Label>{t('delay')}</Label>
                  <InputText
                    type='number'
                    value={delay}
                    onChange={handleDelayChange}
                    disableUnderline
                  />
                </ContentToolbarItem>
              </ContentToolbar>
              <HighlightButtonGrid>
                {eventList.data?.results
                  ?.filter(
                    ({ type }) =>
                      !OMITTED_HIGHLIGHT_EVENT_TYPES.includes(type as number)
                  )
                  .map((event, index) => (
                    <StyledButton
                      key={event.id}
                      color='secondary'
                      variant='contained'
                      backgroundColor={HIGHLIGHT_COLORS[index]}
                      onClick={addManualHighlight(event.id)}
                    >
                      <Icon
                        name={
                          eventIndexToIconDictionary[event?.type ?? ''] ??
                          defaultIconName
                        }
                      />
                      <ActionText lang='de'>{tServer(event.name)}</ActionText>
                    </StyledButton>
                  ))}
              </HighlightButtonGrid>
            </div>
          )}
          {activeView === 'view' && (
            <div>
              <ContentToolbar>
                {canCreateOfficialTicker && (
                  <ContentToolbarItem>
                    <HighlightOptionsSelector
                      value={isOfficial ? 'official-tags' : 'my-tags'}
                      onChange={({ target }) =>
                        setIsOfficial(
                          target.value === 'official-tags' ? true : false
                        )
                      }
                    >
                      <OptionsSelectorMenuItem value='official-tags'>
                        {tPlayerControls('officialTicker')}
                      </OptionsSelectorMenuItem>
                      <OptionsSelectorMenuItem value='my-tags'>
                        {tPlayerControls('userTicker')}
                      </OptionsSelectorMenuItem>
                    </HighlightOptionsSelector>
                  </ContentToolbarItem>
                )}
                <ContentToolbarItem>
                  {highlights.length > 0 && canCreatePlaylist && (
                    <ActionButton
                      text={t('generateAllHighlights')}
                      onClick={handleCreateHighlightsPlaylist}
                    />
                  )}
                </ContentToolbarItem>
              </ContentToolbar>

              <HighlightsList>
                {sortedHighlights.map(highlight => {
                  const { id, event_id, start_time, description } = highlight

                  const startTime = convertSecondsToTime(start_time ?? 0)
                  const event = eventList.data?.results?.find(
                    ({ id }) => id === event_id
                  )

                  const title = tServer(event?.name ?? { en: '', de: '' })
                  const iconName =
                    eventIndexToIconDictionary[event?.type ?? ''] ??
                    defaultIconName

                  const showResourceControls =
                    (isOfficial && canCreateOfficialTicker) || !isOfficial

                  return (
                    <HighlightWrapper
                      key={id}
                      onClick={handleEventClick(start_time)}
                    >
                      <HighlightMeta>
                        <HightlightIconContainer>
                          <Icon name={iconName} />
                        </HightlightIconContainer>

                        <HighlightTextContainer>
                          <HighlightTextPrimaryContainer>
                            <HighlightTitle>{title}</HighlightTitle>
                            <HighlightTimestamp>{startTime}</HighlightTimestamp>
                          </HighlightTextPrimaryContainer>

                          <HighlightTextSecondaryContainer>
                            <HighlightDescription>
                              {description}
                            </HighlightDescription>
                          </HighlightTextSecondaryContainer>
                        </HighlightTextContainer>
                      </HighlightMeta>

                      <HighlightMeta>
                        {showResourceControls && (
                          <ResourceControls
                            onEdit={() => setEditingHighlight(highlight)}
                            onDownload={() =>
                              handleCreateHighlightClip(highlight)
                            }
                            onDelete={handleHighlightDelete(
                              highlight?.id ?? ''
                            )}
                            onTransfer={
                              canTransfer
                                ? event => handleTransferOpen(event, highlight)
                                : undefined
                            }
                          />
                        )}
                      </HighlightMeta>
                    </HighlightWrapper>
                  )
                })}
              </HighlightsList>
            </div>
          )}
        </Content>
      </Root>

      <Menu
        open={Boolean(transferModalAnchor)}
        onClose={handleTransferClose}
        anchorEl={transferModalAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {organizations.map(organization => (
          <MenuItem
            key={organization.id}
            onClick={() => setSelectedOrganization(organization)}
          >
            {organization.name}
          </MenuItem>
        ))}
      </Menu>

      <TransferResourceModal
        open
        type='highlight'
        resource={selectedTicker}
        organization={selectedOrganization}
        onClose={handleTransferClose}
      />
      <HighlightUpdateModal
        open={Boolean(editingHighlight)}
        onClose={handleEditingHighlightModal}
        highlight={editingHighlight}
      />
    </>
  )
}

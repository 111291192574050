import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import { useTheme } from '@mui/material'

// Language
import { changeLanguage } from 'i18next'
import { languages } from 'modules/core/i18n/constants'
import LanguageIcon from 'modules/landing/assets/socials/lang.svg'

// Types
import { HeaderProps } from './Header.interface'

// Components
import { FlexRow, Link, Text } from 'modules/landing/components/shared'
import {
  LanguageWrapper,
  LanguagesList,
  Logo,
  StyledLanguageIcon,
} from './styled'
import { ButtonCTA } from './ButtonCTA'
import { ButtonAuth } from './ButtonAuth'

// Constants
import { LINKS, SOCIAL_LINKS } from 'modules/landing/constants'

// Assets
import LogoSrc from 'modules/landing/assets/logo.svg'

export const HeaderFull = ({
  hasLoggedIn,
  isActivated,
  isLoading,
}: HeaderProps) => {
  const theme = useTheme()

  const [languageMenuOpened, setLanguageMenuOpened] = useState<boolean>(false)
  const { t } = useTranslation(['pages'])

  const handleLanguageMenuClick = () =>
    setLanguageMenuOpened(!languageMenuOpened)

  return (
    <FlexRow
      as='header'
      sx={{
        padding: '14px 25px',
        background: theme.palette.common.black,
        minHeight: 'calc(70px - 28px)',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <FlexRow sx={{ alignItems: 'center' }}>
        <Logo src={LogoSrc} alt='sporttotal' />

        <FlexRow as='nav' sx={{ gap: '50px', marginLeft: '50px' }}>
          {LINKS.map(link => (
            <Link key={`link-${link}`} href={`#${link}`}>
              <Text
                sx={{
                  font: 'normal normal normal 12px/12px "Exo 2"',
                  textTransform: 'uppercase',
                }}
              >
                {t(`landing.header.links.${link}`)}
              </Text>
            </Link>
          ))}
        </FlexRow>
      </FlexRow>
      <FlexRow sx={{ alignItems: 'center' }}>
        <FlexRow
          as='nav'
          sx={{ gap: '30px', alignItems: 'center', justifyContent: 'center' }}
        >
          {SOCIAL_LINKS.map(social => (
            <Link key={`link-${social.name}`} href={social.href}>
              <img src={social.img} alt={social.name} />
            </Link>
          ))}
          <LanguageWrapper onClick={handleLanguageMenuClick}>
            {languageMenuOpened && (
              <LanguagesList>
                {languages.map(lang => (
                  <Tooltip key={lang.languageCode} title={lang.displayName}>
                    <StyledLanguageIcon
                      className='lang'
                      src={lang.iconSrc}
                      alt={lang.displayName}
                      onClick={() => changeLanguage(lang.languageCode)}
                    />
                  </Tooltip>
                ))}
              </LanguagesList>
            )}
            <StyledLanguageIcon src={LanguageIcon} alt='lang' />
          </LanguageWrapper>
        </FlexRow>
        <FlexRow
          sx={{ margin: '0 10px 0 20px', gap: '10px', transition: 'width' }}
        >
          <ButtonAuth isLoading={isLoading} hasLoggedIn={hasLoggedIn} />
        </FlexRow>
        <ButtonCTA
          isLoading={isLoading}
          hasLoggedIn={hasLoggedIn}
          isActivated={isActivated}
        />
      </FlexRow>
    </FlexRow>
  )
}

import { FC, ReactNode } from 'react'
// Components
import { AppBar } from 'modules/core/components'
import { Main } from './OrganizationPageLayout.styled'
// constants
import { SETTINGS_TABS } from '../SettingsPageLayout/constants'

export const OrganizationPageLayout: FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <>
      <AppBar tabs={SETTINGS_TABS} />
      <Main>{children}</Main>
    </>
  )
}

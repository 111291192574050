import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
// Types
import { Invite } from '../UserInviteList/UserInviteList.interface'
// Hooks
import { useInviteDelete, useInviteList } from 'modules/user/hooks'
import { useDebounce } from 'modules/core/hooks'
// Components
import { DisplayIcon, DisplayItem, DisplayItemText } from './UserList.styled'
import { ButtonDeleteInvitationProps } from './UserList.interface'
// Constants
import { INVITES_PAGE_LIMIT } from 'modules/user/constants'

export const ButtonDeleteInvitation = ({
  email,
  onClose,
}: ButtonDeleteInvitationProps) => {
  const { t } = useTranslation(['pages', 'userRole'])
  const debounceSearchValue = useDebounce(email, 500)
  const invites = useInviteList({
    page_limit: INVITES_PAGE_LIMIT,
    search_by: ['email'],
    search: debounceSearchValue,
  })

  const deleteInvite = useInviteDelete()
  const findInviteId = useCallback(
    (email: string) => {
      if (!invites.data) {
        return
      }
      const inviteData = invites.data?.pages.reduce<Invite[]>((data, page) => {
        data.push(...page.results)
        return data
      }, [])
      const inviteId = inviteData.find(invite => invite.email === email)?.id
      return inviteId
    },
    [invites.data]
  )

  const inviteId = findInviteId(email)
  const handleInviteDelete = () => {
    if (inviteId) {
      deleteInvite.mutate(inviteId)
    }
    onClose()
  }

  if (!inviteId) return null
  return (
    <DisplayItem onClick={handleInviteDelete}>
      <DisplayIcon name='delete' />
      <DisplayItemText>{t('pages:admin.button.deleteInvite')}</DisplayItemText>
    </DisplayItem>
  )
}

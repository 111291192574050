import { OrganizationUser } from './OrganizationUserList.interface'

export type UsersAndInvitesListItem = OrganizationUser & {
  inviteId?: string
}

export function mergeUsersAndInvites({
  invites,
  users,
}: {
  invites: UsersAndInvitesListItem[]
  users: OrganizationUser[]
}): UsersAndInvitesListItem[] {
  const resultMap = new Map<string, UsersAndInvitesListItem>()

  invites.forEach(invite => {
    resultMap.set(invite.email, invite)
  })

  users.forEach(user => {
    if (!resultMap.has(user.email)) {
      resultMap.set(user.email, user)
    }
  })

  // Convert the map values back to an array
  return Array.from(resultMap.values())
}

import { FC, ReactNode, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
// components
import { AppBar } from 'modules/core/components'
import { LoaderContainer } from 'modules/core/components'
// hooks
import { useTabStore } from 'modules/analyser/hooks'
import { useTabData } from 'modules/analyser/hooks'
import { useUserAccess, useUserStorage } from 'modules/user/hooks'
// constants
import {
  DEFAULT_LAYOUT_TYPES,
  DEFAULT_TIER_LAYOUT_DATA,
} from 'modules/layout/constants'
import {
  analyserLayoutKeys,
  ANALYZER_DEFAULT_TIER_TABS,
} from 'modules/analyser/constants'

import { Main } from './EventAnalyserPageLayout.styled'

const defaultTabPath = 'dashboard'

export const EventAnalyserPageLayout: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate()
  const { featureTier } = useUserAccess()

  const { currentTabPath, rootPath } = useTabData()
  const userTabs = useUserStorage({ keys: [analyserLayoutKeys.tabsKey] })

  const defaultTabsData = useMemo(
    () => featureTier && DEFAULT_TIER_LAYOUT_DATA[featureTier],
    [featureTier]
  )

  const defaultTabStore = useMemo(
    () => (featureTier ? ANALYZER_DEFAULT_TIER_TABS[featureTier] : []),
    [featureTier]
  )

  const { tabs, addTab, removeTab, renameTab, switchTab } = useTabStore({
    tabsKey: analyserLayoutKeys.tabsKey,
    tabsDataKey: analyserLayoutKeys.tabsDataKey,
    layoutTypesKey: analyserLayoutKeys.layoutTypesKey,
    defaultTabPath,
    defaultTabStore,
    defaultTabsData,
    defaultLayoutTypes: DEFAULT_LAYOUT_TYPES,
  })

  useEffect(() => {
    if (
      !tabs.map(tab => tab.path).includes(currentTabPath) &&
      !userTabs.isLoading &&
      tabs.length !== 0
    ) {
      navigate(`${rootPath}/dashboard`)
    }
  }, [tabs, currentTabPath, rootPath, navigate, userTabs.isLoading])

  return (
    <>
      <AppBar
        editable
        tabs={tabs}
        addTab={addTab}
        removeTab={removeTab}
        renameTab={renameTab}
        clickTab={switchTab}
      />
      <LoaderContainer isLoading={userTabs.isLoading} transitionDelay={0.5}>
        <Main>{children}</Main>
      </LoaderContainer>
    </>
  )
}

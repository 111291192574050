import { FC, ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from 'redux/store'

// TODO: Move 1st party providers to their respective modules and import
import {
  I18nProvider,
  QueryProvider,
  MuiThemeProvider,
  DatePickerProvider,
  DragAndDropProvider,
} from 'providers'
import { SnackbarProvider } from 'modules/core/context'

export const AppProvider: FC<{ children: ReactNode }> = ({ children }) => (
  <Provider store={store}>
    <BrowserRouter>
      <I18nProvider>
        <QueryProvider>
          <MuiThemeProvider>
            <DatePickerProvider>
              <DragAndDropProvider>
                <SnackbarProvider>{children}</SnackbarProvider>
              </DragAndDropProvider>
            </DatePickerProvider>
          </MuiThemeProvider>
        </QueryProvider>
      </I18nProvider>
    </BrowserRouter>
  </Provider>
)

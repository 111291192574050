import { v4 } from 'uuid'
import { playerLayoutConfig } from '../PlayerLayoutConfig'
import { playerControlsLayoutConfig } from '../PlayerControlsLayoutConfig'
import { highlightsLayoutConfig } from '../HighlightsLayoutConfig'

export const tickerLayoutTickerId = v4()

export const TICKER_LAYOUT = {
  lg: [
    { ...playerLayoutConfig.lg, w: 9 },
    { ...playerControlsLayoutConfig.lg, w: 9, y: 11 },
    {
      ...highlightsLayoutConfig.lg,
      x: 9,
      y: 0,
      w: 3,
      h: 14,
      i: tickerLayoutTickerId,
    },
  ],
  md: [
    { ...playerLayoutConfig.md, w: 9 },
    playerControlsLayoutConfig.md,
    {
      ...highlightsLayoutConfig.md,
      x: 9,
      y: 0,
      w: 3,
      h: 14,
      i: tickerLayoutTickerId,
    },
  ],
  sm: [
    { ...playerLayoutConfig.sm, w: 9 },
    playerControlsLayoutConfig.sm,
    {
      ...highlightsLayoutConfig.sm,
      x: 9,
      y: 0,
      w: 3,
      h: 14,
      i: tickerLayoutTickerId,
    },
  ],
  xs: [
    { ...playerLayoutConfig.sm, w: 9 },
    playerControlsLayoutConfig.xs,
    {
      ...highlightsLayoutConfig.xs,
      x: 9,
      y: 0,
      w: 3,
      h: 14,
      i: tickerLayoutTickerId,
    },
  ],
  xxs: [
    { ...playerLayoutConfig.xxs, w: 9 },
    playerControlsLayoutConfig.xxs,
    {
      ...highlightsLayoutConfig.xxs,
      x: 9,
      y: 0,
      w: 3,
      h: 14,
      i: tickerLayoutTickerId,
    },
  ],
}

import { useMutation } from 'react-query'
import { ApiService } from 'services/Api.service'
import { UploadUploadBody } from '@sporttotal-tv/dip-coaching-client'
import { useQueryRetry } from 'modules/core/hooks'

export const UPLOAD_SIGNED_CACHE_KEY = 'upload-signed'

export const useSignedUpload = () => {
  const handleQueryRetry = useQueryRetry()

  return useMutation(
    [UPLOAD_SIGNED_CACHE_KEY],
    (params: UploadUploadBody) => ApiService.upload.signedUpload(params),
    {
      retry: handleQueryRetry,
    }
  )
}

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { UploadEvents as BaseUploadEvents } from '../types'

type UseBaseUploadProps = {
  abortOnUnmount?: boolean
  onChange?: (state: ReturnType<typeof useBaseUpload>) => void
}

type UploadEvents = Partial<Record<keyof BaseUploadEvents, Function>>

type StartUpload = {
  endpoint: string
  file: File
}

export const useBaseUpload = ({
  abortOnUnmount,
  onChange,
}: UseBaseUploadProps = {}) => {
  const [progress, setProgress] = useState<number>()
  const [isUploading, setIsUploading] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)
  const [isError, setIsError] = useState(false)
  const [file, setFile] = useState<File>()

  const requestRef = useRef<XMLHttpRequest>()

  const handleCancelUpload = useCallback(() => {
    setIsCanceled(true)
    if (!requestRef.current) return

    setProgress(undefined)
    setIsUploading(false)
    setIsError(false)
    setIsComplete(false)

    requestRef.current.abort()
  }, [requestRef])

  const handleStartUpload = useCallback(
    ({ endpoint, file }: StartUpload, events?: UploadEvents) => {
      setProgress(0)
      setIsError(false)
      setIsUploading(true)
      setFile(file)

      const request = new XMLHttpRequest()
      requestRef.current = request

      request.open('PUT', endpoint, true)

      request.upload.onprogress = (event: ProgressEvent) => {
        const { loaded, total } = event
        setProgress(Math.round((loaded / total) * 100))
      }

      request.upload.onload = () => {
        setIsUploading(false)
        setProgress(100)
        setIsComplete(true)

        if (events && typeof events.onComplete === 'function') {
          events.onComplete()
        }
      }

      request.upload.onabort = () => {
        setIsError(false)
        setIsUploading(false)
        setIsComplete(false)
      }

      request.upload.onerror = () => {
        setIsError(true)
        setIsUploading(false)
        setIsComplete(false)
      }

      request.onreadystatechange = () => {
        if (request.readyState !== XMLHttpRequest.DONE) return

        if (request.status > 399) {
          setProgress(undefined)
          setIsUploading(false)
          setIsComplete(false)
          setIsError(true)
        }
      }

      request.send(file as XMLHttpRequestBodyInit)
    },
    []
  )

  const isQueued = useMemo(
    () => !isError && !isComplete && !isUploading && !isCanceled,
    [isError, isComplete, isUploading, isCanceled]
  )

  useEffect(() => {
    return () => {
      if (!requestRef.current || !abortOnUnmount) return
      handleCancelUpload()
    }
  }, [abortOnUnmount, handleCancelUpload])

  useEffect(() => {
    if (!onChange) return

    onChange({
      request: requestRef.current,
      start: handleStartUpload,
      cancel: handleCancelUpload,
      isComplete,
      isUploading,
      isQueued,
      isCanceled,
      isError,
      progress,
      file,
    })
  }, [
    onChange,
    requestRef,
    handleStartUpload,
    handleCancelUpload,
    isComplete,
    isUploading,
    isQueued,
    isCanceled,
    isError,
    progress,
    file,
  ])

  return {
    request: requestRef.current,
    start: handleStartUpload,
    cancel: handleCancelUpload,
    isComplete,
    isUploading,
    isQueued,
    isCanceled,
    isError,
    progress,
    file,
  }
}

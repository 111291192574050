import { useCallback, useState } from 'react'
import { Upload } from '../components'
import { DropzoneOptions } from 'react-dropzone'

type UseUploadProps = Pick<
  DropzoneOptions,
  'accept' | 'minSize' | 'maxSize' | 'maxFiles'
>

export const useUpload = (props?: UseUploadProps) => {
  const [file, setFile] = useState<File>()

  const UploadComponent = useCallback(
    () => (
      <Upload {...props} onDrop={([acceptedFiles]) => setFile(acceptedFiles)} />
    ),
    []
  )

  return { file, UploadComponent }
}

import { FC, ReactNode, useCallback, useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
// Hooks
import { useObservable } from 'modules/core/hooks'
import { useUser, useUserAccess } from 'modules/user/hooks'
// Components
import { LoaderContainer } from 'modules/core/components'
// Constants
// Services
import { TokenService } from 'services/Token.service'
// Types
import { UserFeatureTier, UserRole } from 'modules/user/types'

// Providers for Restricted Routes
import { OrganizationProvider } from 'modules/organization/provider'
import { LayoutProvider, SubscriptionProvider } from 'providers'
import { UploadProvider } from 'modules/upload/provider'

interface ProtectedGlobalRouteProps {
  allowedUserRoles?: UserRole[]
  allowedFeatureTiers?: UserFeatureTier[]
  redirectPath?: string
  children: ReactNode
}
interface LoadingProps {
  isLoading: boolean
  hasLoggedIn: boolean
  isActivated: boolean
  isValidated: boolean
}
export const ProtectedRoutes: FC<ProtectedGlobalRouteProps> = ({
  allowedUserRoles,
  allowedFeatureTiers,
  redirectPath = '/analysis/dashboard',
  children,
}) => {
  const token = useObservable(TokenService.getInstance().getToken())
  const { validateUserAccess } = useUserAccess()

  const [{ isLoading, hasLoggedIn, isActivated, isValidated }, setStates] =
    useState<LoadingProps>({
      isLoading: true,
      hasLoggedIn: false,
      isActivated: false,
      isValidated: false,
    })

  const user = useUser({ enabled: hasLoggedIn, retry: false })

  const validate = useCallback(() => {
    return validateUserAccess({
      featureTiers: allowedFeatureTiers,
      roles: allowedUserRoles,
    })
  }, [validateUserAccess, allowedFeatureTiers, allowedUserRoles])

  useEffect(() => {
    if (token) {
      setStates({
        isLoading: true,
        hasLoggedIn: true,
        isActivated: false,
        isValidated: false,
      })
    } else {
      setStates({
        isLoading: false,
        hasLoggedIn: false,
        isActivated: false,
        isValidated: false,
      })
    }
  }, [token])

  useEffect(() => {
    if (user) {
      setStates(prev => {
        if (user.status === 'success' && prev.isLoading) {
          return {
            isLoading: false,
            hasLoggedIn: true,
            isActivated: true,
            isValidated: validate(),
          }
        }
        if (user.status === 'error' && prev.isLoading) {
          return {
            isLoading: false,
            hasLoggedIn: true,
            isActivated: false,
            isValidated: false,
          }
        }
        return prev
      })
    }
  }, [user, validate])

  if (isLoading) {
    return (
      <LoaderContainer variant='full' isLoading={true}>
        <div />
      </LoaderContainer>
    )
  }

  if (!isActivated || !hasLoggedIn) {
    return <Navigate to={'/'} replace />
  }

  if (!isValidated) {
    return <Navigate to={redirectPath} replace />
  }

  return (
    <LayoutProvider>
      <OrganizationProvider>
        <SubscriptionProvider>
          <UploadProvider>{children}</UploadProvider>
        </SubscriptionProvider>
      </OrganizationProvider>
    </LayoutProvider>
  )
}

import { styled } from '@mui/material'

export const Root = styled('div')`
  margin: ${({ theme }) => theme.spacing(6)} 10%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;

  // theme.spacing(12) needs because we have margin theme.spacing(6) at the top and bottom
  height: calc(
    100vh - ${({ theme }) => theme.toolbarHeight}px -
      ${({ theme }) => theme.spacing(12)}
  );
`

export const Main = styled('div')`
  height: 100%;
  padding-right: ${({ theme }) => theme.spacing(5)};
  overflow: scroll;
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(5)};
`
export const HeaderRow = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
export const Title = styled('h1')`
  margin: 0;
`
export const Wrapper = styled('div')`
  padding: ${({ theme }) => theme.spacing(2.5)};
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 100%;
  flex: 1 0 40%;
  overflow: scroll;
`
export const UserAddWrapper = styled('div')`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  cursor: pointer;
`

import { useNavigate } from 'react-router-dom'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from 'modules/core/components'
// Components
import { Button, RequestADemoForm } from 'modules/landing/components/shared'
import { DialogActivation } from './DialogActivation'

// Context
import { PopupContext } from 'modules/landing/contexts/PopupContext/context'
// Constants
import { BUTTON_VARIANTS } from 'modules/landing/components/shared/Button/utils'
import { AnimatePresence } from 'framer-motion'

export const ButtonCTA = ({
  isLoading,
  hasLoggedIn,
  isActivated,
}: {
  isLoading: boolean
  hasLoggedIn: boolean
  isActivated: boolean
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['pages'])

  const { isRequestDemoPopupOpened, setIsRequestDemoPopupOpened } =
    useContext(PopupContext)!
  const onRequestDemoClick = () =>
    setIsRequestDemoPopupOpened(!isRequestDemoPopupOpened)

  const [activationDialogOpened, setActivationDialogOpened] =
    useState<boolean>(false)
  const handleToggleActivationDialog = (flag: boolean) => {
    setActivationDialogOpened(flag)
  }

  if (isLoading) {
    return <p style={{ margin: 0 }}>Authenticating...</p>
  }
  if (!hasLoggedIn) {
    return (
      <>
        <Button
          variant={BUTTON_VARIANTS.primary}
          sx={{
            width: '150px',
            whiteSpace: 'nowrap',
          }}
          onClick={onRequestDemoClick}
        >
          {t(`landing.header.buttons.request_demo`)}
        </Button>
        <AnimatePresence>
          {isRequestDemoPopupOpened && <RequestADemoForm />}
        </AnimatePresence>
      </>
    )
  }
  if (isActivated) {
    return (
      <Button
        variant={BUTTON_VARIANTS.primary}
        sx={{
          width: '150px',
          whiteSpace: 'nowrap',
        }}
        onClick={() => {
          navigate('/analysis/dashboard')
        }}
      >
        {t(`landing.header.buttons.todashboard`)}
      </Button>
    )
  }

  //if user not Activated
  return (
    <>
      <Button
        variant={BUTTON_VARIANTS.primary}
        sx={{
          width: '150px',
          whiteSpace: 'nowrap',
        }}
        onClick={handleToggleActivationDialog.bind(null, true)}
      >
        <Icon name='bell' style={{ color: 'white', marginRight: '0.5em' }} />
        {t(`landing.header.buttons.activation_required`)}
      </Button>
      <DialogActivation
        open={activationDialogOpened}
        onClose={handleToggleActivationDialog.bind(null, false)}
      />
    </>
  )
}

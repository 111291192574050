import { playerLayoutConfig } from '../PlayerLayoutConfig'
import { playerControlsLayoutConfig } from '../PlayerControlsLayoutConfig'

export const DASHBOARD_LAYOUT_CONFIG = {
  lg: [
    { ...playerLayoutConfig.lg, w: 12 },
    { ...playerControlsLayoutConfig.lg, w: 12, y: 11 },
  ],
  md: [{ ...playerLayoutConfig.md, w: 12 }, playerControlsLayoutConfig.md],
  sm: [{ ...playerLayoutConfig.sm, w: 12 }, playerControlsLayoutConfig.sm],
  xs: [{ ...playerLayoutConfig.sm, w: 12 }, playerControlsLayoutConfig.xs],
  xxs: [{ ...playerLayoutConfig.xxs, w: 12 }, playerControlsLayoutConfig.xxs],
}

import { useEffect, useState } from 'react'
// Hooks
import { useAdaptive } from 'modules/landing/hooks/useAdaptive'
import { TokenService } from 'services/Token.service'

// Components
import { HeaderTablet } from './Header.tablet'
import { HeaderMobile } from './Header.mobile'
import { HeaderFull } from './HeaderFull'
// Constants
import { BREAKPOINTS } from 'modules/landing/constants'
import { HeaderProps } from './Header.interface'
import { useObservable } from 'modules/core/hooks'
import { useUser } from 'modules/user/hooks'

export const Header = () => {
  const token = useObservable(TokenService.getInstance().getToken())

  const [{ isLoading, hasLoggedIn, isActivated }, setStates] =
    useState<HeaderProps>({
      isLoading: true,
      hasLoggedIn: false,
      isActivated: false,
    })

  const user = useUser({ enabled: hasLoggedIn, retry: false })

  const isMobile = useAdaptive(0, BREAKPOINTS.mobile)
  const isTablet = useAdaptive(BREAKPOINTS.mobile, BREAKPOINTS.tablet)

  const headerProps = {
    isLoading,
    hasLoggedIn,
    isActivated,
  }
  useEffect(() => {
    if (token) {
      setStates({
        isLoading: true,
        hasLoggedIn: true,
        isActivated: false,
      })
    } else {
      setStates({
        isLoading: false,
        hasLoggedIn: false,
        isActivated: false,
      })
    }
  }, [token])

  useEffect(() => {
    setStates(prev => {
      if (user.status === 'success' && prev.isLoading) {
        return {
          isLoading: false,
          hasLoggedIn: true,
          isActivated: true,
        }
      }
      if (user.status === 'error' && prev.isLoading) {
        return {
          isLoading: false,
          hasLoggedIn: true,
          isActivated: false,
        }
      }
      return prev
    })
  }, [user])

  if (isTablet) return <HeaderTablet {...headerProps} />
  if (isMobile) return <HeaderMobile {...headerProps} />
  return <HeaderFull {...headerProps} />
}

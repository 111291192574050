import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { useQueryRetry } from 'modules/core/hooks'
import { User } from '@sporttotal-tv/dip-coaching-client'
import { UserCacheData } from 'modules/user/types'
import { UseQueryOptions } from 'modules/core/types'

export const USER_RETRIEVE_CACHE_KEY = 'user-retrieve'

export const useUser = (
  queryOptions?: UseQueryOptions<User & { data: UserCacheData }, string[]>
) => {
  const handleQueryRetry = useQueryRetry({ maxRetries: 0 })

  const retry =
    queryOptions?.retry !== undefined ? queryOptions.retry : handleQueryRetry

  return useQuery([USER_RETRIEVE_CACHE_KEY], () => ApiService.user.retrieve(), {
    ...queryOptions,
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: retry,
  })
}

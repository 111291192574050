import { styled } from '@mui/material/styles'
import {
  css,
  IconButton as BaseIconButton,
  Toolbar,
  AppBar as MuiAppBar,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  LinearProgress,
} from '@mui/material'
import { Icon } from 'modules/core/components/Icon'
import { IconButton } from 'modules/core/components/IconButton'
import { Menu as StyledMenu } from 'modules/core/components/Menu'
import { OptionsSelector } from 'modules/core/styled'

export const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<{ open?: boolean }>`
  z-index: ${({ theme }) => theme.zIndex.drawer - 1};
  transition: ${({ theme }) =>
    theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};

  ${({ open, theme }) =>
    open &&
    css`
      margin-left: ${theme.drawerWidth};
      width: calc(100% - ${theme.drawerWidth}px);
      transition: ${theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      })};
    `}
`

export const StyledToolbar = styled(Toolbar)`
  background: ${({ theme }) => theme.palette.primary.main};
  align-items: stretch;
  justify-content: space-between;
  flex: 1;
`

export const TabsContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'open',
})<{ open: boolean }>`
  display: flex;
  flex: 1;
  position: relative;
  transition: all 0.3s;
  opacity: ${({ open }) => (open ? 1 : 0)};
  overflow: auto;
`

export const ToolbarActionsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 20px;
  min-width: 15%;
`

export const UserContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'hidden',
})<{ hidden?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 15px;
  opacity: 1;

  transition: opacity 0.3s;

  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0;
    `}
`

export const UserFullName = styled(Typography)`
  font-size: 1.15rem;
`

export const UserTierRoleWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  align-items: center;
`

export const UserTier = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-transform: capitalize;
`

export const UserRole = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.light};
  text-transform: capitalize;
`

export const UserAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  border: 2px solid ${({ theme }) => theme.palette.primary.contrastText};
`

export const UserAvatarWrapper = styled('div')`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1)};
  cursor: pointer;
`

export const UserText = styled('div')`
  text-align: end;
`

export const Tab = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'active' && prop !== 'editable' && prop !== 'clickable',
})<{ active?: boolean; editable?: boolean; clickable?: boolean }>`
  position: relative;
  border-top: 7px solid
    ${({ theme, active }) =>
      active ? theme.palette.secondary.main : theme.palette.primary.light};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  display: flex;
  min-width: 115px;
  margin-right: ${({ theme }) => theme.spacing(2.5)};
  padding: 0 ${({ theme }) => theme.spacing(2)};
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  ${({ editable }) =>
    editable &&
    css`
      & .MuiButtonBase-root {
        display: none;
      }

      &:hover .MuiButtonBase-root {
        display: inline-flex;
      }
    `}
`

export const CloseIconWrapper = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'hidden',
})<{ hidden?: boolean }>`
  position: absolute;
  right: 0;
  top: 6px;
  padding: 4px;

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`

export const EditIconWrapper = styled(CloseIconWrapper)`
  left: 0;
  right: unset;
`

export const TabIcon = styled(Icon)`
  height: 8px;
  width: 8px;
`

export const Divider = styled('div')`
  height: 30px;
  border-right: 1px solid ${({ theme }) => theme.palette.primary.light};
`

export const AddTabWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1.75)};
`

export const AnimatedChevronIcon = styled(Icon, {
  shouldForwardProp: prop => prop !== 'chevronUp',
})<{ chevronUp?: boolean }>`
  transition: all 0.3s;
  transform: ${({ chevronUp }) => (chevronUp ? 'rotate(0)' : 'rotate(180deg)')};
  height: 10px;
  width: 10px;
`

export const UserMenu = styled(Menu)`
  transform: translateX(${({ theme }) => theme.spacing(2)});

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    transform: translateX(${({ theme }) => theme.spacing(3)});
  }

  & .MuiList-root {
    padding: 0;
    min-width: 192px;
  }
`

export const UserMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1.5, 2)};

  transition: all 0.3s;
  background-color: ${({ theme }) => theme.palette.darkest};
  color: ${({ theme }) => theme.palette.primary.light};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background-color: ${({ theme }) => theme.palette.background.default};
  }

  &:focus {
    background-color: ${({ theme }) => theme.palette.darkest};
    color: ${({ theme }) => theme.palette.primary.light};

    &:hover {
      color: ${({ theme }) => theme.palette.primary.contrastText};
      background-color: ${({ theme }) => theme.palette.background.default};
    }
  }
`

export const UserMenuText = styled(Typography)`
  font-size: 16px;
`

export const LanguageWrapper = styled('div')`
  display: flex;
  align-items: center;
`

export const LanguagesList = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 20px;
  column-gap: 10px;
`

export const LanguageIcon = styled('img')`
  border-radius: 50%;
  aspect-ratio: 1/1;
  height: 20px;
  cursor: pointer;
`

export const OrganizationWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const OrganizationOptionsSelector = styled(OptionsSelector)`
  border: 1px solid ${({ theme }) => theme.palette.secondary.light};
  border-radius: 5px;
`

export const UploadWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`

export const UploadIconWrapper = styled('div')`
  position: relative;
  display: flex;
`

export const UploadIconButton = styled(BaseIconButton)`
  width: unset;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.palette.secondary.main};
  transition: all 0.2s ease;

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
    color: ${({ theme }) => theme.palette.secondary.dark};
  }
`

export const UploadQueueCount = styled(Typography)`
  width: unset;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 0.75rem;
  font-weight: bold;
  transition: all 0.2s ease;

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
    background-color: ${({ theme }) => theme.palette.secondary.dark};
  }
`

export const UploadListWrapper = styled(StyledMenu)`
  transform: translate(25px, 5px);

  & .MuiPaper-root {
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    min-width: 300px;
    max-width: 400px;
  }
`

export const UploadProgressWrapper = styled('div')`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(1.5)};
  left: ${({ theme }) => theme.spacing(2)};
  right: ${({ theme }) => theme.spacing(2)};
  padding: 0;
  margin: 0;
`

export const UploadItemWrapper = styled(MenuItem)`
  width: 300px;
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  min-height: 72px;
  transition: all 0.2s ease;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  overflow: hidden;
  position: relative;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
`

export const UploadMetaWrapper = styled('div')`
  flex: 1;
  display: flex;
  justify-content: space-between;
`

export const UploadTextWrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  margin-right: ${({ theme }) => theme.spacing(1)};
  overflow: hidden;
`

export const UploadTitleRow = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`

export const UploadTitle = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const UploadSize = styled(Typography)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  font-size: 0.75rem;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`

export const UploadStatus = styled('div', {
  shouldForwardProp: prop => prop !== 'isComplete' && prop !== 'isError',
})<{
  isComplete: boolean
  isError: boolean
}>`
  padding: ${({ theme }) => theme.spacing(0.25, 1)};
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: 600;
  background-color: ${({ theme, isComplete, isError }) =>
    isComplete
      ? '#2e7d32'
      : isError
      ? theme.palette.error.main
      : theme.palette.info.main};
  color: #ffffff;
`

export const UploadThumbnail = styled('div')`
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  flex-shrink: 0;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.palette.grey[600]};
  }
`

export const StyledLinearProgress = styled(LinearProgress)`
  height: 4px;
  border-radius: 2px;
  width: 100%;
  min-width: 100%;
  flex-shrink: 0;

  .MuiLinearProgress-bar {
    border-radius: 2px;
  }

  &.MuiLinearProgress-colorSecondary {
    background-color: ${({ theme }) => theme.palette.grey[200]};
  }

  &.MuiLinearProgress-colorError {
    background-color: ${({ theme }) => theme.palette.error.light};
  }
`

export const StyledDeleteButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.text.secondary};

  &:hover {
    color: ${({ theme }) => theme.palette.error.main};
  }
`

export const UploadOptions = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

import { styled } from '@mui/material'
import { motion } from 'framer-motion'
import { Button } from 'modules/landing/components/shared/Button/Button'

export const Logo = styled('img')`
  width: 36px;
  height: 42px;
`

export const BurgerButton = styled(Button)`
  width: auto;
  border: 0px;
`

export const LanguageWrapper = styled('div')`
  display: flex;
  height: 24px;
  align-items: center;
  height: 100%;
  position: relative;
`

export const LanguagesList = styled('div')`
  position: absolute;
  z-index: 100;
  top: 30px;
  grid-gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  column-gap: 10px;
`

export const LanguagesListMobile = styled('div')`
  z-index: 100;
  right: 0;
  display: flex;
  align-items: center;
  column-gap: 20px;
`

export const StyledLanguageIcon = styled('img')`
  border-radius: 50%;
  aspect-ratio: 1/1;
  height: 16px;
  cursor: pointer;
  &.lang:hover {
    transition: all 300ms ease;
    transform: scale(1.1);
  }
`

import * as yup from 'yup'

export const assetCreateWithTeamSchema = yup.object().shape({
  title: yup.string().required('error.title').max(255, 'error.maxNumber'),
  eventStart: yup.number().required('error.eventStart').min(0),
  file: yup.mixed().required('error.file'),
  teamId: yup.string().uuid().required('errors.team'),
})

export const assetCreateWithSportSchema = yup.object().shape({
  title: yup.string().required('error.title').max(255, 'error.maxNumber'),
  eventStart: yup.number().required('error.eventStart').min(0),
  file: yup.mixed().required('error.file'),
  sportId: yup.string().uuid().required('errors.sport'),
})

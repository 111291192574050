import { BehaviorSubject } from 'rxjs'
// Services
import { ApiService } from 'services/Api.service'
// Api
import { api } from 'modules/core/api'
// Constants
import { APP_SSO_AUTH_URL } from 'modules/auth/constants'
// Types
import type { ChangelessBehaviorSubject } from 'modules/core/types'

export class TokenService {
  private static instance: TokenService
  private static timeoutFunc: null | ReturnType<typeof setTimeout>
  private readonly _token = new BehaviorSubject<string | null>(null)
  private readonly _refreshToken = new BehaviorSubject<string | null>(null)

  private constructor() {
    // console.log('TOKEN SERVICE CONSTRUCTED')
  }

  private static createInstance() {
    TokenService.instance = new TokenService()

    const token = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')

    if (token !== null) {
      TokenService.instance.setToken(token)
      api.setBaseHeaders({ Authorization: token })
    }

    if (refreshToken !== null) {
      TokenService.instance.setRefreshToken(refreshToken)
    }

    if (TokenService.timeoutFunc !== null) {
      clearTimeout(TokenService.timeoutFunc)
      TokenService.timeoutFunc = null
    }
  }

  public static getInstance(): TokenService {
    let isFirstLoad = false
    if (!TokenService.instance) {
      isFirstLoad = true
      TokenService.createInstance()
    }
    if (isFirstLoad) {
      TokenService.instance.verifyToken()
    }
    return TokenService.instance
  }

  private setRefreshTimeout(delay: number) {
    if (TokenService.timeoutFunc !== null) {
      clearTimeout(TokenService.timeoutFunc)
      TokenService.timeoutFunc = null
    }

    TokenService.timeoutFunc = setTimeout(() => {
      TokenService.getInstance().refreshToken()
    }, delay * 1000)
  }

  public async refreshToken() {
    ApiService.auth
      .refreshToken()
      .then(async response => {
        if (!response) {
          return
        }

        if (response.data.accessToken && response.data.refreshToken) {
          TokenService.getInstance().setToken(response.data.accessToken)
          TokenService.getInstance().setRefreshToken(response.data.refreshToken)

          this.setRefreshTimeout(response.data.expiresIn ?? 1000)
        }
      })
      .catch(() => {
        TokenService.getInstance().removeToken()
        TokenService.getInstance().removeRefreshToken()
      })
  }

  public async requestToken(code: string): Promise<void> {
    const response = await ApiService.auth.getAccessToken(code)
    if (!response) return

    if (response.status === 401) return this.logout()

    if (response.data.accessToken && response.data.refreshToken) {
      TokenService.getInstance().setToken(response.data.accessToken)
      TokenService.getInstance().setRefreshToken(response.data.refreshToken)

      this.setRefreshTimeout(response.data.expiresIn ?? 1000)
    }
  }

  public verifyToken() {
    ApiService.auth.verifyToken().then(response => {
      if (response.status !== 200) {
        TokenService.instance.removeAllTokens()
      }
    })
  }

  public getToken(): ChangelessBehaviorSubject<string | null> {
    return this._token
  }

  public setToken(token: string) {
    localStorage.setItem('accessToken', token)
    api.setBaseHeaders({ Authorization: token })

    this._token.next(token)
  }

  private removeToken() {
    localStorage.removeItem('accessToken')
    if (TokenService.timeoutFunc !== null) {
      clearTimeout(TokenService.timeoutFunc)
      TokenService.timeoutFunc = null
    }

    this._token.next(null)
  }

  public setRefreshToken(token: string) {
    localStorage.setItem('refreshToken', token)

    this._refreshToken.next(token)
  }

  private removeRefreshToken() {
    localStorage.removeItem('refreshToken')

    this._refreshToken.next(null)
  }

  public getRefreshToken(): ChangelessBehaviorSubject<string | null> {
    return this._refreshToken
  }

  public removeAllTokens() {
    this.removeToken()
    this.removeRefreshToken()
  }

  public logout() {
    this.removeAllTokens()

    setTimeout(
      () =>
        (window.location.href = `${APP_SSO_AUTH_URL}/users/sign_out?redirect_url=${window.location.origin}`),
      250
    )
  }
}

import { FC, ReactNode } from 'react'
import { Routes as RoutesData } from 'modules/core/types'
import { Route, Routes } from 'react-router-dom'
import { UnrestrictedLayoutProvider } from 'providers/UnrestrictedLayoutProvider'

export const UnrestrictedRoutes = ({
  routes,
  layout,
}: {
  routes: RoutesData[]
  layout: FC<{
    children: ReactNode
  }>
}) => {
  return (
    <Routes>
      {routes.map(
        ({ path, element, layout: NestedLayout = layout }, routeIndex) => (
          <Route
            key={routeIndex}
            path={path}
            element={
              <UnrestrictedLayoutProvider>
                <NestedLayout>{element}</NestedLayout>
              </UnrestrictedLayoutProvider>
            }
          />
        )
      )}
    </Routes>
  )
}

import { AssetApiService } from 'modules/asset/services/asset.api.service'
import { TeamApiService } from 'modules/team/services/team.api.service'
import { PlaylistApiService } from 'modules/playlist/services/playlist.api.service'
import { PlaylistAssetApiService } from 'modules/playlist/services/playlist-asset.api.service'
import { MatchApiService } from 'modules/match/services/match.api.service'
import { UserApiService } from 'modules/user/services/user.api.service'
import { InviteApiService } from 'modules/user/services/invite.api.service'
import { EventApiService } from 'modules/event/services/event.service'
import { CompetitionApiService } from 'modules/competition/services/competition.api.service'
import { AuthApiService } from 'modules/auth/services/auth.service'
import { TelestrationGroupApiService } from 'modules/telestration/service/telestrationGroup.api.service'
import { TelestrationApiService } from 'modules/telestration/service/telestration.api.service'
import { CacheApiService } from 'modules/cache/services/cache.api.service'
import { ClubApiService } from 'modules/user/services/club.api.service'
import { AssociationApiService } from 'modules/user/services/association.api.service'
import { SportApiService } from 'modules/sport/services/sport.api.service'
import { AdminApiService } from 'modules/admin/services/admin.api.service'
import { TeamPlayerApiService } from 'modules/team-player/services/team-player.api.service'
import { TeamPlayerContractApiService } from 'modules/team-player/services/team-player-contract.api.service'
import { TeamPlayerInjuryApiService } from 'modules/team-player/services/team-player-injury.api.service'
import { TeamPlayerStatsApiService } from 'modules/team-player/services/team-player-stats.api.service'
import { TeamPlayerStatusApiService } from 'modules/team-player/services/team-player-status.api.service'
import { TeamPlayerTransferApiService } from 'modules/team-player/services/team-player-transfer.api.service'
import { CurrencyApiService } from 'modules/core/services/currency.api.service'
import { FrameApiService } from 'modules/frame/services/frame.api.service'
import { HighlightApiService } from 'modules/highlight/services'
import { CalendarApiService } from 'modules/calendar/services/calendar.api.service'
import { ProductApiService } from 'modules/product/services/product.api.service'
import { SubscriptionApiService } from 'modules/subscription/services/subscription.api.service'
import { StripeApiService } from 'modules/stripe/services/stripe.api.service'
import { ContactApiService } from 'modules/contact/services/contact.api.service'
import { OrganizationApiService } from 'modules/organization/services'
import { OrganizationAdminApiService } from 'modules/organization-admin/services'
import { ResourceService } from 'modules/resource/services'
import { CameraService } from 'modules/camera/services/camera.api.service'
import { ScheduleApiService } from 'modules/schedule/services'
import { PlayingSurfaceApiService } from 'modules/playing-surface/services/playing-surface.service'
import { UploadApiService } from 'modules/upload/services'

export const ApiService = {
  admin: AdminApiService,
  user: UserApiService,
  team: TeamApiService,
  match: MatchApiService,
  highlight: HighlightApiService,
  asset: AssetApiService,
  playlist: PlaylistApiService,
  playlistAsset: PlaylistAssetApiService,
  event: EventApiService,
  competition: CompetitionApiService,
  auth: AuthApiService,
  telestrationGroup: TelestrationGroupApiService,
  telestration: TelestrationApiService,
  cache: CacheApiService,
  invite: InviteApiService,
  association: AssociationApiService,
  club: ClubApiService,
  sport: SportApiService,
  teamPlayer: TeamPlayerApiService,
  teamPlayerContract: TeamPlayerContractApiService,
  teamPlayerInjury: TeamPlayerInjuryApiService,
  teamPlayerStats: TeamPlayerStatsApiService,
  teamPlayerStatus: TeamPlayerStatusApiService,
  teamPlayerTransfer: TeamPlayerTransferApiService,
  currency: CurrencyApiService,
  frame: FrameApiService,
  calendar: CalendarApiService,
  product: ProductApiService,
  subscription: SubscriptionApiService,
  stripe: StripeApiService,
  contact: ContactApiService,
  organization: OrganizationApiService,
  organizationAdmin: OrganizationAdminApiService,
  resource: ResourceService,
  camera: CameraService,
  schedule: ScheduleApiService,
  playingSurface: PlayingSurfaceApiService,
  upload: UploadApiService,
}

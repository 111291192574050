// Constants
import {
  playerLayoutConfig,
  playerControlsLayoutConfig,
  heatmapLayoutConfig,
  minimapLayoutConfig,
  statisticsLayoutConfig,
  telestrationLayoutConfig,
  assetsLayoutConfig,
  playlistLayoutConfig,
  highlightsLayoutConfig,
} from 'modules/layout/configs'
import {
  TELESTRATION_TAB_LAYOUT,
  telestrationLayoutAssetsId,
  telestrationLayoutPlayerControlsId,
  telestrationLayoutPlayerId,
  telestrationLayoutTelestrationId,
} from 'modules/layout/configs/presetLayouts/telestrationTabLayoutConfig'
import {
  CLIPPING_TAB_LAYOUT,
  clippingLayoutAssetsId,
  clippingLayoutPlayerControlsId,
  clippingLayoutPlayerId,
  clippingLayoutPlaylistId,
} from 'modules/layout/configs/presetLayouts/clippingTabLayoutConfig'
import {
  COMPARISON_TAB_LAYOUT,
  comparisonLayoutPlayerOneId,
  comparisonLayoutPlayerOneControlsId,
  comparisonLayoutTelestrationOneId,
  comparisonLayoutPlayerTwoId,
  comparisonLayoutPlayerTwoControlsId,
  comparisonLayoutTelestrationTwoId,
} from 'modules/layout/configs/presetLayouts/comparsionLayoutConfig'
import { playersLayoutConfig } from 'modules/layout/configs/PlayersLayoutConfig'
// Layout
import { LayoutIndex } from 'modules/layout/constants/LayoutIndex'
// Types
import { LayoutConfigBreakpoints } from 'modules/layout/types'
import { Layouts } from 'react-grid-layout'
import { DASHBOARD_LAYOUT_CONFIG } from '../configs/presetLayouts/dashboardLayoutConfig'
import {
  TICKER_LAYOUT,
  tickerLayoutTickerId,
} from '../configs/presetLayouts/tickerLayoutConfig'
import { UserFeatureTier } from 'modules/user/types'

const LAYOUT_COLUMNS: { [key in LayoutConfigBreakpoints]: number } = {
  lg: 12,
  md: 8,
  sm: 4,
  xs: 2,
  xxs: 1,
}

const BREAKPOINTS_VALUES: Record<LayoutConfigBreakpoints, number> = {
  lg: 1200,
  md: 996,
  sm: 768,
  xs: 480,
  xxs: 0,
}

const BREAKPOINTS: LayoutConfigBreakpoints[] = ['lg', 'md', 'sm', 'xs', 'xxs']

export const EMPTY_LAYOUT = { lg: [], md: [], sm: [], xs: [], xxs: [] }

const LAYOUT_CONFIGS = {
  [LayoutIndex.PLAYER]: playerLayoutConfig,
  [LayoutIndex.PLAYER_CONTROLS]: playerControlsLayoutConfig,
  [LayoutIndex.HEATMAP]: heatmapLayoutConfig,
  [LayoutIndex.MINIMAP]: minimapLayoutConfig,
  [LayoutIndex.STATISTICS]: statisticsLayoutConfig,
  [LayoutIndex.ASSETS]: assetsLayoutConfig,
  [LayoutIndex.TELESTRATION]: telestrationLayoutConfig,
  [LayoutIndex.PLAYLIST]: playlistLayoutConfig,
  [LayoutIndex.PLAYERS]: playersLayoutConfig,
  [LayoutIndex.HIGHLIGHTS]: highlightsLayoutConfig,
}

const DEFAULT_LAYOUT_INFO = {
  [playerLayoutConfig.lg.i]: { type: LayoutIndex.PLAYER },
  [playerControlsLayoutConfig.lg.i]: {
    type: LayoutIndex.PLAYER_CONTROLS,
  },
  [heatmapLayoutConfig.lg.i]: { type: LayoutIndex.HEATMAP },
  [minimapLayoutConfig.lg.i]: { type: LayoutIndex.MINIMAP },
  [statisticsLayoutConfig.lg.i]: { type: LayoutIndex.STATISTICS },
  [assetsLayoutConfig.lg.i]: { type: LayoutIndex.ASSETS },
}

const DEFAULT_LITE_TAB_LAYOUT_DATA: Record<string, Layouts> = {
  dashboard: DASHBOARD_LAYOUT_CONFIG,
  ticker: TICKER_LAYOUT,
  comparison: COMPARISON_TAB_LAYOUT,
}

const DEFAULT_LAYOUT_DATA: Record<string, Layouts> = {
  dashboard: DASHBOARD_LAYOUT_CONFIG,
  clipping: CLIPPING_TAB_LAYOUT,
  telestration: TELESTRATION_TAB_LAYOUT,
  comparison: COMPARISON_TAB_LAYOUT,
}

const DEFAULT_TIER_LAYOUT_DATA: Record<
  UserFeatureTier,
  Record<string, Layouts>
> = {
  lite: DEFAULT_LITE_TAB_LAYOUT_DATA,
  basic: DEFAULT_LAYOUT_DATA,
  advanced: DEFAULT_LAYOUT_DATA,
  professional: DEFAULT_LAYOUT_DATA,
}

const DEFAULT_LAYOUT_TYPES = {
  [playerLayoutConfig.lg.i]: LayoutIndex.PLAYER,
  [playerControlsLayoutConfig.lg.i]: LayoutIndex.PLAYER_CONTROLS,
  [assetsLayoutConfig.lg.i]: LayoutIndex.ASSETS,
  [telestrationLayoutPlayerId]: LayoutIndex.PLAYER,
  [telestrationLayoutPlayerControlsId]: LayoutIndex.PLAYER_CONTROLS,
  [telestrationLayoutAssetsId]: LayoutIndex.ASSETS,
  [telestrationLayoutTelestrationId]: LayoutIndex.TELESTRATION,
  [clippingLayoutPlayerId]: LayoutIndex.PLAYER,
  [clippingLayoutPlayerControlsId]: LayoutIndex.PLAYER_CONTROLS,
  [clippingLayoutAssetsId]: LayoutIndex.ASSETS,
  [clippingLayoutPlaylistId]: LayoutIndex.PLAYLIST,
  [comparisonLayoutPlayerOneId]: LayoutIndex.PLAYER,
  [comparisonLayoutPlayerOneControlsId]: LayoutIndex.PLAYER_CONTROLS,
  [comparisonLayoutTelestrationOneId]: LayoutIndex.TELESTRATION,
  [comparisonLayoutPlayerTwoId]: LayoutIndex.PLAYER,
  [comparisonLayoutPlayerTwoControlsId]: LayoutIndex.PLAYER_CONTROLS,
  [comparisonLayoutTelestrationTwoId]: LayoutIndex.TELESTRATION,
  [tickerLayoutTickerId]: LayoutIndex.HIGHLIGHTS,
}

export {
  LAYOUT_COLUMNS,
  BREAKPOINTS,
  DEFAULT_LAYOUT_TYPES,
  DEFAULT_LAYOUT_INFO,
  LAYOUT_CONFIGS,
  BREAKPOINTS_VALUES,
  DEFAULT_TIER_LAYOUT_DATA,
}

import { useTranslation } from 'react-i18next'
// Utils
import { handleRedirect } from 'modules/auth/utils'
// Components
import { Button } from 'modules/landing/components/shared'
// Constants
import { BUTTON_VARIANTS } from 'modules/landing/components/shared/Button/utils'
import { APP_SSO_LOGIN_URL, APP_SSO_REGISTER_URL } from 'modules/auth/constants'
import { Loader } from 'modules/core/styled'
import { TokenService } from 'services/Token.service'

export const ButtonAuth = ({
  isLoading,
  hasLoggedIn,
}: {
  isLoading: boolean
  hasLoggedIn: boolean
}) => {
  const { t } = useTranslation(['pages'])

  const handleLogout = () => TokenService.getInstance().logout()

  if (isLoading) {
    return <Loader size={26} />
  }
  if (hasLoggedIn) {
    return (
      <Button variant={BUTTON_VARIANTS.default} onClick={handleLogout}>
        {t(`landing.header.buttons.logout`)}
      </Button>
    )
  }
  return (
    <>
      <Button
        variant={BUTTON_VARIANTS.default}
        onClick={() => handleRedirect(APP_SSO_LOGIN_URL)}
      >
        {t(`landing.header.buttons.login`)}
      </Button>
      <Button
        variant={BUTTON_VARIANTS.default}
        onClick={() => handleRedirect(APP_SSO_REGISTER_URL)}
      >
        {t(`landing.header.buttons.sign_up`)}
      </Button>
    </>
  )
}

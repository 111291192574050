import { useCallback, useMemo, DragEvent, FC } from 'react'
import _ from 'lodash'
import { useTheme } from '@mui/material'
// Components
import { Icon } from 'modules/core/components'
import { Tooltip } from 'modules/core/styled'
// Hooks
import { useReduxDispatch, useReduxSelector } from 'modules/core/hooks'
import { useUser, useUserAccess } from 'modules/user/hooks'
import { generateId } from 'modules/core/helpers/generateId'
// Redux
import {
  selectCurrentLayout,
  selectLayoutState,
  setLayoutsPositionStack,
} from 'modules/layout/redux'
import { setDropItem, setInsideDragging } from 'modules/analyser/redux'
// Layout
import {
  defaultLayoutEntries,
  LayoutIndex,
  componentItemColors,
} from 'modules/layout/constants'
// Constants
import { COMPONENT_LIMIT } from 'modules/layout/constants'
// Types
import { LayoutConfig, LayoutConfigBreakpoints } from 'modules/layout/types'

import { LayoutPaletteContainer, PaletteIcon } from './LayoutPalette.styled'
import { LayoutPaletteProps } from './LayoutPalette.interface'

export const LayoutPalette: FC<LayoutPaletteProps> = ({ paletteInfo }) => {
  const theme = useTheme()
  const dispatch = useReduxDispatch()
  const { breakpoint, layoutTypes } = useReduxSelector(selectLayoutState)
  const layouts = useReduxSelector(selectCurrentLayout)

  const user = useUser()
  const { validateUserAccess } = useUserAccess()

  const layoutEntries: Record<LayoutIndex, number> = useMemo(() => {
    const layoutsIds = layouts.map(layout => layout.i)

    return Object.entries(layoutTypes)
      .filter(([key]) => layoutsIds.includes(key))
      .reduce(
        (el, [_, value]) => ({
          ...el,
          [value]: el[value] + 1,
        }),
        defaultLayoutEntries
      )
  }, [layoutTypes, layouts])

  const currentBreakpoint: LayoutConfigBreakpoints = useMemo(
    () => breakpoint ?? 'lg',
    [breakpoint]
  )

  const paletteItems = useMemo(
    () =>
      Object.entries(paletteInfo).filter(([, { allowedFeatureTier }]) =>
        validateUserAccess({ featureTiers: allowedFeatureTier })
      ),
    [user.data, paletteInfo]
  )

  const handleIconClick = useCallback(
    (layoutIndex: string) => () => {
      const layoutsIds = layouts.map(layout => layout.i)
      const entryId = Object.entries(layoutTypes).find(
        ([key, value]) => value === layoutIndex && layoutsIds.includes(key)
      )
      if (entryId) {
        dispatch(setLayoutsPositionStack(entryId[0]))
      }
    },
    [layoutTypes, dispatch, layouts]
  )

  const handleDragStartCapture = useCallback(() => {
    dispatch(setInsideDragging(true))
  }, [dispatch])

  const handleDragEndCapture = useCallback(() => {
    dispatch(setInsideDragging(false))
  }, [dispatch])

  const handleDragStart = useCallback(
    (
        layoutConfig: LayoutConfig & {
          type: LayoutIndex
        }
      ) =>
      (e: DragEvent<HTMLDivElement>) => {
        dispatch(
          setDropItem({
            dropItem: {
              ..._.pick(layoutConfig[currentBreakpoint], ['i', 'w', 'h']),
              i: generateId(),
              type: layoutConfig.type,
            },
            dropConfig: _.pick(layoutConfig[currentBreakpoint], [
              'minW',
              'minH',
              'maxW',
              'maxH',
            ]),
          })
        )
        return e.dataTransfer?.setData('text/plain', '')
      },
    [dispatch, currentBreakpoint]
  )

  return (
    <LayoutPaletteContainer>
      {paletteItems.map(([layoutIndex, { icon, name, layoutConfig }]) => (
        <Tooltip
          key={layoutIndex}
          arrow
          backgroundColor={theme.palette.darkest}
          title={name}
          placement='left'
        >
          <PaletteIcon
            onClick={handleIconClick(layoutIndex)}
            color={
              componentItemColors[layoutEntries[layoutIndex as LayoutIndex]]
            }
            isDraggingAllowed={
              layoutEntries[layoutIndex as LayoutIndex] < COMPONENT_LIMIT
            }
            onDragStartCapture={handleDragStartCapture}
            onDragEndCapture={handleDragEndCapture}
            draggable={
              layoutEntries[layoutIndex as LayoutIndex] < COMPONENT_LIMIT
            }
            // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
            onDragStart={handleDragStart(layoutConfig)}
          >
            <Icon name={icon} />
            {name}
          </PaletteIcon>
        </Tooltip>
      ))}
    </LayoutPaletteContainer>
  )
}

import { FC, ReactNode } from 'react'
import { AppBar } from 'modules/core/components'
import { Main } from './CameraPageLayout.styled'
//constants
import { SETTINGS_TABS } from '../SettingsPageLayout/constants'
export const CameraPageLayout: FC<{ children: ReactNode }> = ({ children }) => (
  <>
    <AppBar tabs={SETTINGS_TABS} />
    <Main>{children}</Main>
  </>
)

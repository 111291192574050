import { useQuery } from 'react-query'

// TODO: Extract out
type AIHighlight = {
  exactStartMs: number
  exactStart: string
  startMs: number
  durationMs: number
  start: string
  value: number
  duration: string
  type: number
}

// TODO: Move to generic fetch external file hook with generics typing

export const MATCH_HIGHLIGHTS_FILE_RETRIEVE_CACHE_KEY =
  'match-highlights-file-retrieve'

export const useMatchHighlightsFile = (endpoint?: string) => {
  return useQuery<AIHighlight[]>(
    MATCH_HIGHLIGHTS_FILE_RETRIEVE_CACHE_KEY,
    async () => {
      if (!endpoint) return []

      const response = await fetch(endpoint)
      return response.json()
    },
    {
      enabled: Boolean(endpoint),
      retry: false,
    }
  )
}

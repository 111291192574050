import { FC, ReactNode } from 'react'
// Components
import { AppBar } from 'modules/core/components'
import { LoaderContainer } from 'modules/core/components'
// Hooks
import { useUser } from 'modules/user/hooks'
import { Main } from './SettingsPageLayout.styled'
//constants
import { SETTINGS_TABS } from './constants'
export const SettingsPageLayout: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const user = useUser()

  return (
    <>
      <AppBar tabs={SETTINGS_TABS} />
      <LoaderContainer isLoading={!user.isFetched} transitionDelay={0.5}>
        <Main>{children}</Main>
      </LoaderContainer>
    </>
  )
}

import { FC, useMemo } from 'react'
import _ from 'lodash'
// hooks
import { useTheme } from '@mui/material'
import { useUser, useUserAccess } from 'modules/user/hooks'
import { useReduxSelector } from 'modules/core/hooks'
// components
import { LayoutItem, LayoutPalette } from 'modules/layout/components'
import { GridComponentsLayout } from 'modules/analyser/components'
// layout
import { LayoutComponents } from 'modules/layout/constants'
// redux
import { selectInsideDragging } from 'modules/analyser/redux'
import { selectCurrentLayout, selectLayoutState } from 'modules/layout/redux'
// context
import { ComponentInfoContextProvider } from 'modules/generic/context'

import { Root } from './ComponentsView.styled'
import { Component, ComponentsViewProps } from './ComponentsView.interface'
import { PlayerContextProvider } from '../../../video-player/context'

export const ComponentsView: FC<ComponentsViewProps> = ({ paletteInfo }) => {
  const user = useUser()
  const theme = useTheme()
  const layouts = useReduxSelector(selectCurrentLayout)
  const insideDragging = useReduxSelector(selectInsideDragging)
  const { layoutsPositionStack, layoutTypes } =
    useReduxSelector(selectLayoutState)

  const { validateUserAccess } = useUserAccess()

  const components: Component[] = useMemo(() => {
    if (!layouts) return []

    return layouts.reduce<Component[]>((arr, layout) => {
      const layoutId = layout.i
      const layoutType = layoutTypes[layoutId]
      const layoutComponent = LayoutComponents[layoutType]

      if (!layoutComponent) return arr

      const layoutPosition =
        _.findIndex(layoutsPositionStack, pos => pos === layoutId) + 2 // to make -1 to zIndex: 1

      const isLastElement =
        !insideDragging &&
        layoutPosition - 2 === layoutsPositionStack.length - 1

      const { allowedFeatureTier } = paletteInfo[layoutType]
      if (!validateUserAccess({ featureTiers: allowedFeatureTier })) return arr

      return [
        ...arr,
        {
          id: layoutId,
          layoutIndex: layoutType,
          Component: layoutComponent.component,
          ProviderComponent: layoutComponent.provider,
          zIndex: isLastElement
            ? theme.reactGridLayout.placeholder + 1
            : layoutPosition,
        },
      ]
    }, [])
  }, [
    layouts,
    layoutsPositionStack,
    layoutTypes,
    theme.reactGridLayout.placeholder,
    insideDragging,
    user.data,
  ])

  // TODO: add loader when we switching between tabs
  return (
    <Root>
      {/*TODO: Return when we will have analyser header*/}
      {/* <AnalyserHeader /> */}
      <GridComponentsLayout>
        {components.map(
          ({ id, layoutIndex, Component, ProviderComponent, zIndex }) => (
            <LayoutItem
              key={id}
              itemId={id}
              ProviderComponent={ProviderComponent}
              style={{ zIndex }}
            >
              <ComponentInfoContextProvider
                componentId={id}
                layoutIndex={layoutIndex}
              >
                <PlayerContextProvider>
                  <Component />
                </PlayerContextProvider>
              </ComponentInfoContextProvider>
            </LayoutItem>
          )
        )}
      </GridComponentsLayout>
      <LayoutPalette paletteInfo={paletteInfo} />
    </Root>
  )
}

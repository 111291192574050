import { Route, Routes } from 'react-router-dom'

// Constants
import { routes } from 'modules/core/routes'

// Components for Restricted / Unrestricted Routes
import { UnrestrictedRoutes } from './UnrestrictedRoutes'
import { ProtectedRoutes } from './ProtectedRoutes'

export const Router = () => {
  return (
    <Routes>
      {routes.map(
        (
          {
            layoutPath,
            routes: childRoutes,
            allowedUserRoles,
            allowedFeatureTiers,
            layout: Layout,
          },
          index
        ) => {
          if (!allowedUserRoles && !allowedFeatureTiers) {
            return (
              <Route
                key={index}
                path={layoutPath}
                element={
                  <UnrestrictedRoutes routes={childRoutes} layout={Layout} />
                }
              />
            )
          }
          return (
            <Route
              key={index}
              path={layoutPath}
              element={
                <ProtectedRoutes
                  allowedUserRoles={allowedUserRoles}
                  allowedFeatureTiers={allowedFeatureTiers}
                >
                  <Routes>
                    {childRoutes.map(
                      (
                        {
                          path,
                          element,
                          restricted,
                          layout: NestedLayout = Layout,
                        },
                        routeIndex
                      ) => (
                        <Route
                          key={routeIndex}
                          path={path}
                          element={<NestedLayout>{element}</NestedLayout>}
                        />
                      )
                    )}
                  </Routes>
                </ProtectedRoutes>
              }
            />
          )
        }
      )}
    </Routes>
  )
}

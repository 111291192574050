import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
// Utils
import { format } from 'date-fns'
// Types
import { OrganizationListProps } from './OrganizationList.interface'
// Components
import {
  HeaderElement,
  Row,
  Table,
  BodyElement,
  HeaderElementSmall,
  BodyElementSmall,
} from 'modules/core/styled'
import { RoleElement, TableWrapper } from './OrganizationList.styled'
import { IconButton } from 'modules/core/components'
import { OrganizationContextMenu } from './OrganizationContextMenu'

export const OrganizationList: FC<OrganizationListProps> = ({
  organizations,
  handleRowClick,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'organization' })
  const [contextMenuProps, setContextMenuProps] = useState<{
    organizationId?: string
    anchorEl?: HTMLElement
    open: boolean
  }>({ open: false })

  const handleMenuOpen =
    (organizationId: string) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      console.log({
        selectedId: organizationId,
        anchor: event.currentTarget,
        open: true,
      })
      setContextMenuProps({
        organizationId: organizationId,
        anchorEl: event.currentTarget,
        open: true,
      })
    }

  const handleMenuClose = () => setContextMenuProps({ open: false })

  return (
    <TableWrapper>
      <Table>
        <Row>
          <HeaderElement>{t('table.row.name')}</HeaderElement>
          <HeaderElement>{t('table.row.owner')}</HeaderElement>
          <HeaderElement>{t('table.row.created_at')}</HeaderElement>
          <HeaderElement>{t('table.row.updated_at')}</HeaderElement>
          <HeaderElementSmall />
        </Row>
        {organizations?.results?.map(organization => (
          <Row
            interactive
            key={organization.id}
            onClick={() => handleRowClick(organization.id)}
          >
            <BodyElement>{organization.name}</BodyElement>
            <RoleElement>
              {organization.owner?.firstname} {organization.owner?.lastname}
            </RoleElement>
            <BodyElement>
              {format(new Date(+organization.created_at * 1000), 'dd/MM/yy')}
            </BodyElement>
            <BodyElement>
              {format(new Date(+organization.updated_at * 1000), 'dd/MM/yy')}
            </BodyElement>
            <BodyElementSmall>
              <IconButton
                name='vertical-menu'
                onClick={handleMenuOpen(organization.id)}
              />
            </BodyElementSmall>
          </Row>
        ))}
      </Table>
      <OrganizationContextMenu
        {...contextMenuProps}
        onClose={handleMenuClose}
      />
    </TableWrapper>
  )
}

// Hooks
import { useState, useMemo, useEffect, useCallback, FC } from 'react'
import { useBaseUpload, useSignedUpload } from '../hooks'
// Context
import { UploadContext } from 'modules/upload/context'
// Types
import { QueueUploadParams, Upload, UploadEvents } from 'modules/upload/types'
import { UploadContextProviderProps } from 'modules/upload/context/UploadContext/UploadContext.interface'

export const UploadProvider: FC<UploadContextProviderProps> = ({
  children,
}) => {
  const { mutate } = useSignedUpload()
  const [uploads, setUploads] = useState<Upload[]>([])

  const handleUploadOnChange = useCallback(
    (upload: ReturnType<typeof useBaseUpload>) => {
      setUploads(currentUploads => {
        const updatedUploads = [...currentUploads]

        if (!upload.file) return updatedUploads

        const updatedUploadIndex = updatedUploads.findIndex(
          u => 'file' in u && u.file === upload.file
        )

        if (updatedUploadIndex !== -1)
          // @ts-ignore
          updatedUploads[updatedUploadIndex] = {
            ...upload,
            display_name: updatedUploads[updatedUploadIndex].display_name,
          }

        return updatedUploads
      })
    },
    []
  )

  const { start } = useBaseUpload({ onChange: handleUploadOnChange })

  const activeUpload = useMemo(
    () => uploads.filter(({ isUploading }) => isUploading).at(0),
    [uploads]
  )

  const handleStartUpload = useCallback(
    (file: File, event?: UploadEvents) => {
      mutate(
        {
          content_length: file.size,
          content_type: file.type,
        },
        {
          onSuccess({ upload_url, public_url }) {
            start(
              { endpoint: upload_url, file },
              { onComplete: event?.onComplete?.bind(null, public_url) }
            )
          },
        }
      )
    },
    [mutate, start]
  )

  const queueUpload = useCallback((upload: QueueUploadParams) => {
    setUploads(currentUploads => {
      const updatedUploads = [...currentUploads]

      updatedUploads.push({
        ...upload,
        progress: undefined,
        isUploading: false,
        isComplete: false,
        isQueued: true,
        isError: false,
      })

      return updatedUploads
    })
  }, [])

  useEffect(() => {
    if (activeUpload) return
    if (!uploads.length) return

    const nextUpload = uploads.filter(({ isQueued }) => isQueued).at(0)
    if (nextUpload?.file) handleStartUpload(nextUpload.file, nextUpload.events)
  }, [uploads, activeUpload, handleStartUpload])

  return (
    <UploadContext.Provider
      value={{
        uploads,
        activeUpload,
        queueUpload,
      }}
    >
      {children}
    </UploadContext.Provider>
  )
}

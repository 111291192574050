// libs
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { changeLanguage } from 'i18next'
import { useTheme } from '@mui/material'
import { Tooltip } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { motion } from 'framer-motion'

// Types
import { HeaderProps } from './Header.interface'

// Components
import { FlexColumn, FlexRow } from 'modules/landing/components/shared/Flex'
import { Link } from 'modules/landing/components/shared/Link'
import { Text } from 'modules/landing/components/shared/Text/'
import {
  BurgerButton,
  LanguagesListMobile,
  Logo,
  StyledLanguageIcon,
} from './styled'
import { ButtonAuth } from './ButtonAuth'
import { ButtonCTA } from './ButtonCTA'

// Contenxt
import { PopupContext } from 'modules/landing/contexts/PopupContext/context'

// Constants
import { BUTTON_VARIANTS } from 'modules/landing/components/shared/Button/utils'
import { languages } from 'modules/core/i18n/constants'
import { LINKS } from 'modules/landing/constants'

// Assets
import LogoSrc from 'modules/landing/assets/logo.svg'

export const HeaderTablet = ({
  hasLoggedIn,
  isActivated,
  isLoading,
}: HeaderProps) => {
  const theme = useTheme()

  const { t } = useTranslation(['pages'])

  const { isBurgerOpened, setIsBurgerOpened } = useContext(PopupContext)!

  const onBurgerClick = () => setIsBurgerOpened(!isBurgerOpened)

  return (
    <>
      <FlexRow
        as='header'
        sx={{
          width: '100%',
          height: '70px',
          padding: '14px 25px',
          background: theme.palette.common.black,
        }}
      >
        <FlexRow
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '10px',
          }}
        >
          <Logo sx={{ width: '30px', height: '30px' }} src={LogoSrc} />
          <FlexRow
            sx={{
              gap: '20px',
            }}
          >
            <LanguagesListMobile>
              {languages.map(lang => (
                <Tooltip key={lang.languageCode} title={lang.displayName}>
                  <StyledLanguageIcon
                    className='lang'
                    src={lang.iconSrc}
                    alt={lang.displayName}
                    onClick={() => {
                      changeLanguage(lang.languageCode)
                    }}
                  />
                </Tooltip>
              ))}
            </LanguagesListMobile>

            <BurgerButton
              variant={BUTTON_VARIANTS.default}
              onClick={onBurgerClick}
            >
              <MenuIcon />
            </BurgerButton>
          </FlexRow>
        </FlexRow>
      </FlexRow>
      {isBurgerOpened && (
        <motion.div>
          <FlexColumn
            sx={{
              width: '100%',
              padding: '25px',
              background: theme.palette.common.black,
              position: 'absolute',
              top: '70px',
              gap: '20px',
              zIndex: 99,
              alignItems: 'center',
            }}
          >
            {LINKS.map(link => (
              <Link
                key={`link-${link}`}
                href={`#${link}`}
                onClick={onBurgerClick}
              >
                <Text
                  sx={{
                    font: "normal normal normal 20px/12px 'Exo 2'",
                    textTransform: 'uppercase',
                  }}
                >
                  {t(`landing.header.links.${link}`)}
                </Text>
              </Link>
            ))}

            <FlexColumn sx={{ gap: '10px', marginTop: '10px' }}>
              <ButtonAuth isLoading={isLoading} hasLoggedIn={hasLoggedIn} />
              <ButtonCTA
                isLoading={isLoading}
                hasLoggedIn={hasLoggedIn}
                isActivated={isActivated}
              />
            </FlexColumn>
          </FlexColumn>
        </motion.div>
      )}
    </>
  )
}

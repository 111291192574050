import { useCallback, useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Hooks
import {
  useAdminOrganization,
  useAdminOrganizationUserList,
} from 'modules/organization/hooks'
import { useOrganizationAdminUserInviteList } from 'modules/organization-admin/hooks'

// Components
import { IconButton } from 'modules/core/components'
import {
  HeaderRow,
  Root,
  Main,
  Wrapper,
  Title,
  UserAddWrapper,
} from './AdminOrganizationView.styled'
import {
  OrganizationAdminEdit,
  OrganizationUserList,
} from 'modules/organization/components'
import { OrganizationUserInviteModal } from 'modules/organization-admin/components'
// import { UserInviteList } from 'modules/user/components'

// Constants
import { routeNames } from 'modules/core/constants'
import { mergeUsersAndInvites } from 'modules/organization/components/OrganizationUserList/helper'

export const AdminOrganizationView = () => {
  const { id } = useParams()
  const { t } = useTranslation('pages', { keyPrefix: 'admin' })

  const navigate = useNavigate()
  const [organizationUserInviteOpen, isOrganizationUserInviteOpen] =
    useState(false)

  // get the orgainzation data
  const organization = useAdminOrganization(id ?? '')

  // get user data and setup user operations
  const { data: usersData } = useAdminOrganizationUserList({
    organization_id: id,
    expand: ['user'],
  })
  const users = useMemo(
    () =>
      usersData?.results.map(({ id, user }) => ({
        ...user!,
        organizationUserId: id,
      })) ?? [],
    [usersData?.results]
  )
  const handleUserOnClick = useCallback(
    (id: string) => () => {
      navigate(`${routeNames.admin.path}/user/${id}`)
    },
    [navigate]
  )

  // get invites data and setup invite operations
  const { data: invitesData } = useOrganizationAdminUserInviteList({
    group: id ?? '',
    expand: ['issued_to'],
  })
  const invites = useMemo(
    () =>
      invitesData?.results.map(({ id, issued_to: user }) => ({
        ...user!,
        organizationUserId: user!.id,
        inviteId: id,
      })) ?? [],
    [invitesData?.results]
  )
  const usersAndInvites = mergeUsersAndInvites({
    users,
    invites,
  })
  return (
    <Root>
      <Main>
        {organization.data && (
          <>
            <Title>{organization.data.name}</Title>
            <Wrapper>
              <HeaderRow>
                <UserAddWrapper
                  onClick={() => isOrganizationUserInviteOpen(true)}
                >
                  {t('context.inviteUser')} <IconButton name='plus-circle' />
                </UserAddWrapper>
              </HeaderRow>
              <OrganizationUserList
                organizationId={id ?? ''}
                users={usersAndInvites}
                handleOnUserClick={handleUserOnClick}
              />
            </Wrapper>

            <OrganizationAdminEdit organization={organization.data} />

            <OrganizationUserInviteModal
              open={organizationUserInviteOpen}
              setOpen={isOrganizationUserInviteOpen}
              organizationId={organization.data.id}
            />
          </>
        )}
      </Main>
    </Root>
  )
}

import { useTranslation } from 'react-i18next'
import { Dialog, useTheme } from '@mui/material'

// custom hooks
import { useAdaptive } from 'modules/landing/hooks/useAdaptive'

// components
import { Button, Text, FlexColumn } from 'modules/landing/components/shared'

// constants
import { BREAKPOINTS } from 'modules/landing/constants'
import { BUTTON_VARIANTS } from 'modules/landing/components/shared/Button/utils'

const IS_MOBILE = false

export const DialogActivation = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const theme = useTheme()

  const { t } = useTranslation(['pages'])

  const isMobile = useAdaptive(0, BREAKPOINTS.mobile)
  const handleModalClick = (e: React.MouseEvent) => e.stopPropagation()

  return (
    <Dialog open={open} onClose={onClose}>
      <FlexColumn
        sx={{
          width: isMobile ? '100%' : 'calc(338px - 64px)',
          padding: IS_MOBILE ? '31px 32px' : '21px 32px',
          paddingBottom: '31px',
          background: theme.palette.primary.main,
          alignItems: 'center',
        }}
        onClick={e => handleModalClick(e)}
      >
        <Text
          sx={{
            width: '100%',
            font: IS_MOBILE
              ? 'normal normal normal 36px/24px Bebas Neue'
              : 'normal normal normal 32px/24px Bebas Neue',
            letterSpacing: 0,
            color: theme.palette.secondary.main,
            marginBottom: '21px',
            textAlign: IS_MOBILE ? 'center' : 'start',
          }}
        >
          {t('landing.request_activation.title')}
        </Text>

        <Text
          sx={{
            font: IS_MOBILE
              ? "normal normal normal 16px/24px 'Exo 2'"
              : "normal normal normal 14px/24px 'Exo 2'",
            letterSpacing: 0,
            color: theme.palette.primary.contrastText,
            marginBottom: '21px',
          }}
        >
          {t('landing.request_activation.text')}
        </Text>
        <Button
          variant={BUTTON_VARIANTS.primary}
          sx={{
            width: '150px',
            whiteSpace: 'nowrap',
          }}
          onClick={onClose}
        >
          Close
        </Button>
      </FlexColumn>
    </Dialog>
  )
}

import { FC } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
// Hooks
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useUser } from 'modules/user/hooks'
import { useOrganizationCreate } from 'modules/organization/hooks'
// Components
import { TextInput } from 'modules/core/components'
import { ActionButton, ModalDialog } from 'modules/core/components'
// Schemas
import { organizationSchema } from 'modules/organization/schemas'
// Types
import {
  OrganizationCreateFormData,
  OrganizationCreateModalProps,
} from './OrganizationCreateModal.interface'

export const OrganizationCreateModal: FC<OrganizationCreateModalProps> = ({
  open,
  onClose,
}) => {
  const user = useUser()
  const { mutate } = useOrganizationCreate()
  const { t } = useTranslation('pages', { keyPrefix: 'organization' })

  const { handleSubmit, register } = useForm({
    resolver: yupResolver(organizationSchema),
    defaultValues: {
      name: '',
    },
  })

  const handleCreateOrganization = (data: OrganizationCreateFormData) => {
    if (!user.data) return

    mutate(data, {
      onSuccess: onClose,
      // TODO: Notify error occurred
      onError: console.error,
    })
  }

  return (
    <ModalDialog
      title={t('modal.createOrganization.create')}
      open={open}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(handleCreateOrganization)}>
        <TextInput
          label={t('modal.createOrganization.name')}
          {...register('name')}
        />
        <ActionButton
          type='submit'
          icon='save'
          text={t('modal.createOrganization.create')}
          position='end'
        />
      </form>
    </ModalDialog>
  )
}

import { useAdminOrganizationDelete } from 'modules/organization/hooks'
import {
  DisplayIcon,
  DisplayItem,
  DisplayItemText,
  DisplayMenu,
} from './OrganizationList.styled'

export const OrganizationContextMenu = ({
  anchorEl,
  organizationId,
  open,
  onClose,
}: {
  anchorEl?: HTMLElement | null
  organizationId?: string
  open: boolean
  onClose: () => void
}) => {
  const deleteOrganization = useAdminOrganizationDelete()
  const handleDelete = () => {
    if (organizationId) deleteOrganization.mutate(organizationId)
  }
  return (
    <DisplayMenu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <DisplayItem onClick={handleDelete}>
        <DisplayIcon name='delete' />
        <DisplayItemText>Delete</DisplayItemText>
        {/* <DisplayItemText>{t('pages:admin.button.deleteInvite')}</DisplayItemText> */}
      </DisplayItem>
    </DisplayMenu>
  )
}

import { LayoutConfigBreakpoints } from 'modules/layout/types'
import ColorHash from 'color-hash'
import { parse } from 'date-fns'

const colorHash = new ColorHash()

/*
  Used to convert second to time format 'HH:MM:SS'
 */
export const convertSecondsToTime = (seconds: number) => {
  if (isNaN(seconds)) {
    return '00:00:00'
  }

  const isoString = new Date(seconds * 1000).toISOString()

  return isoString.slice(11, 19)
}

const secondsInMilliseconds = 1000
const minuteInSeconds = 60
const hourInSeconds = minuteInSeconds * 60

/*
  Used to convert from time format 'HH:MM:SS' to seconds
 */
export const convertTimeToSeconds = (time: string, returnNan = false) => {
  const arr = time.split(':')
  const matches = time.match(/:/g) || []
  if (arr.length !== 3 || time.length !== 8 || matches.length !== 2) {
    return returnNan ? NaN : 0
  }

  return +arr[0] * hourInSeconds + +arr[1] * minuteInSeconds + +arr[2]
}

/*
  Used to convert from time format 'HH:MM:SS' to seconds
 */
export const convertMillisecondsToSeconds = (milliseconds: number) => {
  if (isNaN(milliseconds)) {
    return 0
  }

  return milliseconds * secondsInMilliseconds
}

/*
  Used to convert string to valid path
 */
export const convertStringToPathName = (str: string) => {
  return str.toLowerCase().replace(/[\s,./\\]+/g, '-')
}

/*
  Used to convert width to corresponding breakpoint
 */
export const convertWidthToBreakpoint = (
  width: number
): LayoutConfigBreakpoints => {
  if (width <= 540) return 'xxs'
  if (width <= 828) return 'xs'
  if (width <= 1056) return 'sm'
  if (width <= 1260) return 'md'
  return 'lg'
}

/*
  Used to convert string to acronym
 */
export const convertStringToAcronym = (str: string): string => {
  const matches = str.match(/\b(\w)/g)

  if (matches === null) return ''

  return matches.join('').toUpperCase()
}

/*
  Used to convert date from 'dd/mm/yyyy' to timestamp
 */
export const convertDateToTimestamp = (date: string): number | undefined => {
  if (date === '' || date.length !== 10) return undefined

  return parse(date, 'MM/dd/yyyy', new Date()).getTime() / 1000
}

export const stringToHex = (input: string) => colorHash.hex(input)

/*
  Used to convert bytes to human readable format
 */
export const convertBytesToHumanReadable = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'

  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`
}

import { FC, useEffect, useMemo, useState } from 'react'
import { format } from 'date-fns'
import { yupResolver } from '@hookform/resolvers/yup'
// Hooks
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { useTError } from 'modules/core/i18n/hooks'
import { useUpload, useUploadContext } from 'modules/upload/hooks'
// import { useSportList } from 'modules/sport/hooks'
import { useUserTeams } from 'modules/user/hooks'
import { useMatchCreate } from 'modules/match/hooks'
// Components
import { DateTimePicker } from '@mui/x-date-pickers'
import {
  Autocomplete,
  ModalDialog,
  TextInput,
  TextInputLabel,
} from 'modules/core/components'
// Schema
import {
  assetCreateWithSportSchema,
  assetCreateWithTeamSchema,
} from 'modules/asset/schemas'
// Constants
import { DATE_TIME_FORMAT } from './AssetCreateModel.constants'
// Types
import { MatchCreatePayload } from '@sporttotal-tv/dip-coaching-client'
import {
  AssetCreateFormFields,
  AssetCreateModelProps,
} from './AssetCreateModel.interface'

import { InputTextField } from 'modules/core/components/Autocomplete/Autocomplete.styled'
import {
  FormControlWrapper,
  InputWrapper,
} from 'modules/core/components/TextInput/TextInput.styled'
import { SubmitButton } from './AssetCreateModel.styled'

export const AssetCreateModel: FC<AssetCreateModelProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'asset' })
  const { tError } = useTError('components', { keyPrefix: 'asset' })

  const { data: userTeams } = useUserTeams({ expand: ['team'] })
  // const { data: sports } = useSportList()
  const { mutate } = useMatchCreate()

  const { queueUpload } = useUploadContext()
  const { file, UploadComponent } = useUpload()

  const [eventStart, setEventStart] = useState(Date.now())

  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<AssetCreateFormFields>({
    resolver: yupResolver(
      userTeams?.total === 0
        ? assetCreateWithSportSchema
        : assetCreateWithTeamSchema
    ),
  })

  useEffect(() => setValue('eventStart', eventStart), [eventStart, setValue])
  useEffect(() => file && setValue('file', file), [file, setValue])

  const teamOptions = useMemo(() => {
    if (!userTeams) return []

    return userTeams.results.map(({ team }) => ({
      value: team?.id ?? '',
      label: team?.name ?? '',
    }))
  }, [userTeams])

  // const sportsOptions = useMemo(() => {
  //   if (!sports) return []

  //   return sports.results.map(({ id, name }) => ({
  //     value: id,
  //     label: name,
  //   }))
  // }, [sports])

  const onSubmit = (fields: AssetCreateFormFields) => {
    const assetBody: Partial<MatchCreatePayload> = {
      title: fields.title,
      event_start: Math.round(fields.eventStart / 1000),
    }

    // @ts-ignore
    if ('sportId' in fields) assetBody.sport_id = fields.sportId
    // @ts-ignore
    if ('teamId' in fields) assetBody.teams = [{ id: fields.teamId }]

    queueUpload({
      file: fields.file,
      display_name: fields.title,
      events: {
        onComplete: publicUrl =>
          mutate({
            ...assetBody,
            match_assets: [{ type: 'broadcast', url: publicUrl }],
          } as MatchCreatePayload),
      },
    })

    onClose()
  }

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      icon='plus-circle'
      title={t('form.asset.create.toolbar.title')}
      disableImplicitOnClose
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label={t('form.asset.create.title')}
          errorMessage={tError(errors.title?.message)}
          inputProps={{ 'aria-label': 'title' }}
          {...register('title')}
        />

        <InputWrapper>
          <TextInputLabel>
            {t('form.asset.create.startDateTime')}
          </TextInputLabel>

          <Controller
            name='eventStart'
            control={control}
            render={({ field }) => (
              <DateTimePicker
                {...field}
                value={eventStart}
                onChange={selectedDateTime =>
                  setEventStart(new Date(`${selectedDateTime}`).getTime())
                }
                renderInput={params => (
                  <InputTextField
                    {...params}
                    value={eventStart}
                    sx={{ flex: 3 }}
                    error={Boolean(errors.eventStart)}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: params.InputProps?.endAdornment,
                      endAdornment: undefined,
                    }}
                    inputProps={{
                      ...params.inputProps,
                      value: format(eventStart, DATE_TIME_FORMAT),
                      disabled: true,
                      'aria-label': 'eventStart',
                    }}
                  />
                )}
              />
            )}
          />
        </InputWrapper>

        {teamOptions.length > 0 && (
          <InputWrapper>
            <TextInputLabel>{t('form.asset.create.team')}</TextInputLabel>
            <FormControlWrapper>
              <Controller
                name='teamId'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={onChange}
                    options={teamOptions}
                    inputProps={{ value }}
                  />
                )}
              />
            </FormControlWrapper>
          </InputWrapper>
        )}

        {/* {teamOptions.length === 0 && (
          <InputWrapper>
            <TextInputLabel>{t('form.asset.create.sport')}</TextInputLabel>
            <FormControlWrapper>
              <Controller
                name='sportId'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={onChange}
                    options={sportsOptions}
                    inputProps={{ value }}
                  />
                )}
              />
            </FormControlWrapper>
          </InputWrapper>
        )} */}

        <UploadComponent />

        <SubmitButton
          type='submit'
          icon='save'
          text={t('form.asset.create.submit')}
          position='end'
        />
      </form>
    </ModalDialog>
  )
}

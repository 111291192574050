import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { LayoutIndex } from 'modules/layout/constants'
import { Layout } from 'react-grid-layout'

export interface DropItem {
  i: string
  w: number
  h: number
  type: LayoutIndex
}

export interface DropState {
  // Drag & Drop
  dropItem: DropItem
  dropConfig?: Partial<Layout>
  insideDragging: boolean
}

const DEFAULT_DROP_ITEM = {
  i: 'drop',
  w: 1,
  h: 1,
  type: LayoutIndex.PLAYER,
}

const initialState: DropState = {
  dropItem: DEFAULT_DROP_ITEM,
  insideDragging: false,
}

export const dropSlice = createSlice({
  name: 'drop',
  initialState,
  reducers: {
    setDropItem: (
      state,
      action: PayloadAction<{
        dropItem: DropItem
        dropConfig?: Partial<Layout>
      }>
    ) => {
      state.dropItem = action.payload.dropItem
      state.dropConfig = action.payload.dropConfig
    },

    setInsideDragging: (state, action: PayloadAction<boolean>) => {
      state.insideDragging = action.payload
    },
  },
})

export const { setDropItem, setInsideDragging } = dropSlice.actions

export const dropReducer = dropSlice.reducer

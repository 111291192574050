import { FC, useCallback, useContext, useRef, useState, useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
// Hooks
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePlaylistCreate } from 'modules/playlist/hooks'
import { useOutsideClick } from 'modules/core/hooks'
import { useTError } from 'modules/core/i18n/hooks'
import { useUserSports } from 'modules/user/hooks'
// Components
import { Tooltip, Typography } from '@mui/material'
import { Icon, IconButton, SearchInput } from 'modules/core/components'
import { ToolbarControls, ToolbarHeader } from 'modules/generic/components'
import { ToolbarDivider } from 'modules/generic/styled'
import { VideoPlayerSelector } from 'modules/video-player/components'
// schema
import { playlistSchema } from 'modules/playlist/schemas'
// context
import { ComponentInfoContext } from 'modules/generic/context'

import {
  ToolbarContainer,
  Toolbar,
  StyledModal,
  ModalContent,
  ToolbarTitle,
  ModalHeader,
  ModalTitle,
  ModalTitleWrapper,
  InputWrapper,
  ButtonWrapper,
  FullWidthFormControl,
  InfoErrorIcon,
  Label,
  SaveButton,
  SaveIcon,
  InputText,
  FormWrapper,
  ToolbarActions,
  EventSelectIcon,
  EventSelectorWrapper,
  ToolbarControlsWrapper,
} from './PlaylistToolbar.styled'
import {
  PlaylistCreateFormData,
  PlaylistToolbarProps,
} from './PlaylistToolbar.interface'

export const PlaylistToolbar: FC<PlaylistToolbarProps> = ({
  filterOpen,
  handleFilterToggle,
  setSearch,
  search,
}) => {
  const { layoutIndex, componentId: layoutId } =
    useContext(ComponentInfoContext)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [searchActive, setSearchActive] = useState<boolean>(false)
  const playlistCreate = usePlaylistCreate()
  const { data: sports } = useUserSports()
  const { t } = useTranslation('components', { keyPrefix: 'playlist' })
  const { t: filterT } = useTranslation('components', { keyPrefix: 'filter' })
  const { tError } = useTError('components', { keyPrefix: 'playlist' })

  const modalRef = useRef<HTMLDivElement>(null)
  const handleOutsideClick = useCallback(() => setModalOpen(false), [])
  useOutsideClick(modalRef, handleOutsideClick)

  const hasSports = useMemo(() => {
    if (!sports) return false
    return sports.length > 0
  }, [sports])

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PlaylistCreateFormData>({
    resolver: yupResolver(playlistSchema),
  })

  const onSubmit = (data: PlaylistCreateFormData) => {
    playlistCreate.mutate({ ...data, public: true })
    setModalOpen(false)
    reset()
  }

  return (
    <ToolbarContainer>
      <ToolbarTitle>
        <ToolbarHeader layoutId={layoutId} layoutIndex={layoutIndex}>
          <IconButton name='plus-circle' onClick={() => setModalOpen(true)} />
          <ToolbarDivider />
          <VideoPlayerSelector />
        </ToolbarHeader>
      </ToolbarTitle>
      <ToolbarActions full={searchActive}>
        <Toolbar />
        {searchActive && (
          <SearchInput
            sx={{ height: 26 }}
            startAdornment={undefined}
            placeholder='Search'
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        )}
        {!searchActive && (
          <>
            <EventSelectorWrapper
              onClickCapture={hasSports ? handleFilterToggle : undefined}
              disabled={!hasSports}
            >
              <Typography>{filterT('title')}</Typography>
              <EventSelectIcon
                name='chevron-small-down'
                chevronUp={!filterOpen}
                disabled={!hasSports}
              />
            </EventSelectorWrapper>
          </>
        )}
        <Toolbar />
      </ToolbarActions>
      <ToolbarControlsWrapper>
        <Toolbar />
        <IconButton
          name='search'
          onClick={() => setSearchActive(prev => !prev)}
        />
        <ToolbarControls layoutId={layoutId} layoutIndex={layoutIndex} />
      </ToolbarControlsWrapper>

      {/* TODO: Check if generic ModalDialog can be used here */}
      <StyledModal
        closeAfterTransition
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <ModalContent ref={modalOpen ? modalRef : undefined}>
          <ModalHeader>
            <ModalTitleWrapper>
              <Icon name='pencil' />
              <ModalTitle>{t('form.create.formTitle')}</ModalTitle>
            </ModalTitleWrapper>
            <IconButton
              sx={{ width: '26px', height: '26px' }}
              name='close-circle'
              onClick={() => setModalOpen(false)}
            />
          </ModalHeader>
          <FormWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputWrapper>
                <Label>{t('form.create.title')}</Label>
                <FullWidthFormControl>
                  <InputText
                    placeholder=''
                    disableUnderline
                    {...register('title')}
                  />
                  <Tooltip title={tError(errors.title?.message) ?? ''}>
                    <InfoErrorIcon hidden={!errors.title?.message} />
                  </Tooltip>
                </FullWidthFormControl>
              </InputWrapper>

              <InputWrapper>
                <Label>{t('form.create.description')}</Label>
                <FullWidthFormControl>
                  <InputText
                    placeholder=''
                    disableUnderline
                    multiline
                    minRows={2}
                    maxRows={2}
                    {...register('description')}
                  />
                  <Tooltip title={tError(errors.description?.message) ?? ''}>
                    <InfoErrorIcon hidden={!errors.description?.message} />
                  </Tooltip>
                </FullWidthFormControl>
              </InputWrapper>
              <ButtonWrapper>
                <SaveButton type='submit' color='secondary' variant='contained'>
                  <SaveIcon name='save' />
                  {t('form.create.save')}
                </SaveButton>
              </ButtonWrapper>
            </form>
          </FormWrapper>
        </ModalContent>
      </StyledModal>
    </ToolbarContainer>
  )
}

import { styled } from '@mui/material'

export const Root = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: inherit;
  padding: ${({ theme }) => theme.spacing(0.5)};
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.secondary.main};
  border: 1px dashed ${({ theme }) => theme.palette.secondary.main};
  cursor: pointer;
`

import { css, styled } from '@mui/material'

export const Table = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(3)};
`

export const Row = styled('div', {
  shouldForwardProp: prop => prop !== 'interactive',
})<{ interactive?: boolean }>`
  transition: all 0.3s;
  display: flex;
  ${({ interactive }) =>
    interactive &&
    css`
      cursor: pointer;
      &:hover {
        background-color: rgba(150, 152, 155, 0.2);
      }
    `}
`

export const HeaderElement = styled('div')`
  flex: 1;
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 12px;
  padding: ${({ theme }) => theme.spacing(0, 0.5)};
  height: 40px;
`

export const HeaderElementSmall = styled(HeaderElement)`
  flex: unset;
  width: 30px;
`

export const BodyElement = styled('div')`
  flex: 1;
  height: 40px;
  font-size: 14px;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  padding: ${({ theme }) => theme.spacing(0, 0.5)};
  transition: all 0.3s;
  display: flex;
  align-items: center;
  overflow: hidden;
`

export const BodyElementSmall = styled(BodyElement)`
  flex: unset;
  width: 30px;
`

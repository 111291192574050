import { createMutationHook } from 'modules/core/api/hooks/utils'
import { ApiService } from 'services/Api.service'
import { MATCH_LIST_CACHE_KEY } from './useMatchList'

export const [useMatchCreate] = createMutationHook(
  ApiService.match.create,
  queryClient => ({
    onSuccess: () => queryClient.invalidateQueries(MATCH_LIST_CACHE_KEY),
  })
)

// Constants
import { USER_ROLE, FEATURE_TIER } from 'modules/user/constants'

export const SETTINGS_TABS = [
  {
    id: 'view',
    name: 'Account',
    useFullPath: true,
    path: 'account',
    fullPath: '/settings/account',
    static: true,
  },
  {
    id: 'advance',
    name: 'Advanced',
    useFullPath: true,
    path: 'advanced',
    fullPath: '/settings/advanced',
    static: true,
  },
  {
    id: 'organization',
    name: 'Organization',
    useFullPath: true,
    path: 'organization',
    fullPath: '/organization',
    static: true,
    allowedUserRoles: [USER_ROLE.COACH, USER_ROLE.ADMIN, USER_ROLE.ROOT],
    allowedFeatureTier: [
      FEATURE_TIER.BASIC,
      FEATURE_TIER.ADVANCED,
      FEATURE_TIER.PROFESSIONAL,
    ],
  },
  {
    id: 'camera',
    name: 'Camera',
    useFullPath: true,
    path: 'cameras',
    fullPath: '/cameras',
    static: true,
    allowedUserRoles: [USER_ROLE.COACH, USER_ROLE.ADMIN, USER_ROLE.ROOT],
  },
]

import {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  useMemo,
  FC,
} from 'react'
import { useQueryClient } from 'react-query'
// Hooks
import { useTranslation } from 'react-i18next'
import {
  useReduxDispatch,
  useReduxSelector,
  useDebounce,
} from 'modules/core/hooks'
import {
  useUserCacheCreate,
  USER_CACHE_RETRIEVE_CACHE_KEY,
} from 'modules/cache/hooks'
import { useUserStorageMatchData } from 'modules/match/hooks'
// Components
import { Collapse, Popover } from '@mui/material'
import {
  MenuItem,
  ResourceControls,
  TextHoverScroll,
} from 'modules/core/components'
import { ClipPreview } from 'modules/asset/components'
// Redux
import { setMediaItem, selectMediaLocator } from 'modules/video-player/redux'
// Utils
import { differenceInSeconds, format } from 'date-fns'
import { convertSecondsToTime } from 'modules/core/utils'
import {
  createSetMatchPayload,
  isMatchLive,
  isMatchUpcoming,
  findDownloadableMatchLocators,
  isMatchPlayable,
} from 'modules/match/utils'
// context
import { ComponentInfoContext } from 'modules/generic/context'
import { VideoPlayerContext } from 'modules/video-player/context'
// constants
import { LayoutIndex } from 'modules/layout/constants'
import { MATCH_LOCATOR_TYPE } from 'modules/match/constants'

import {
  AnimatedChevronIcon,
  CollapseControlsWrapper,
  IconWrapper,
  LiveIndicator,
  MatchDescriptionItem,
  MatchDescriptionWrapper,
  MatchInfoItem,
  MatchInfoWrapper,
  Root,
  UpcomingIcon,
  Title,
  TitleRow,
  TitleRows,
  TitleSecondary,
  Vs,
  DownloadAssetList,
} from './MatchPreview.styled'
import { MatchPreviewProps } from './MatchPreview.interface'

export const MatchPreview: FC<MatchPreviewProps> = ({
  match,
  filter,
  active = false,
}) => {
  const { id, teams, assets, event_start, event_end } = match

  const dispatch = useReduxDispatch()
  const queryClient = useQueryClient()
  const { t } = useTranslation(['core', 'time'])

  const { componentId } = useContext(ComponentInfoContext)
  const { playerId } = useContext(VideoPlayerContext)
  const { mutate: userCacheCreate } = useUserCacheCreate()

  const matchRef = useRef<HTMLDivElement>(null)
  const mediaLocator = useReduxSelector(state =>
    selectMediaLocator(state, playerId ?? '')
  )

  const useMatchTitle = useMemo(() => match.teams?.length === 1, [match])

  const isLive = useMemo(() => isMatchLive(match), [match])
  const isUpcoming = useMemo(
    () => !isLive && isMatchUpcoming(match),
    [isLive, match]
  )

  const { parentKey, childKey } = useMemo(
    () => ({
      parentKey: `${LayoutIndex.ASSETS}-${componentId}`,
      childKey: id,
    }),
    [componentId, id]
  )

  const { focused, collapsed } = useUserStorageMatchData(parentKey, childKey)
  const [internalCollapsed, setInternalCollapsed] = useState<boolean>(collapsed)
  const debounceCollapsed = useDebounce<boolean>(internalCollapsed, 500)

  useEffect(() => {
    const data: Record<string, any> | undefined = queryClient.getQueryData([
      USER_CACHE_RETRIEVE_CACHE_KEY,
      {
        keys: [parentKey],
      },
    ])

    if (
      data === undefined ||
      data?.[parentKey]?.[childKey]?.collapsed === debounceCollapsed ||
      (data?.[parentKey]?.[childKey]?.collapsed === undefined &&
        debounceCollapsed)
    ) {
      return
    }

    userCacheCreate({
      [parentKey]: {
        ...data?.[parentKey],
        [childKey]: {
          collapsed: debounceCollapsed,
        },
      },
    })
  }, [debounceCollapsed, userCacheCreate, queryClient, childKey, parentKey])

  useEffect(() => {
    if (focused) {
      setInternalCollapsed(false)

      matchRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [focused, id])

  const filteredAssets = useMemo(() => {
    let assetsFiltered = assets || []

    if (filter?.tags && filter.tags.length > 0) {
      assetsFiltered = assetsFiltered.filter(asset => {
        return filter?.tags?.some(tag =>
          asset.tags.map(item => item.toLowerCase()).includes(tag.toLowerCase())
        )
      })
    }

    if (filter?.events && filter.events.length > 0) {
      assetsFiltered = assetsFiltered.filter(asset => {
        return filter?.events?.some(event => asset.event_id === event)
      })
    }

    return assetsFiltered
  }, [assets, filter])

  const [downloadAssetListAnchor, setDownloadAssetListAnchor] =
    useState<HTMLButtonElement | null>(null)

  const downloadableMatchLocators = useMemo(
    () => findDownloadableMatchLocators(match),
    [match]
  )

  const isPlayable = useMemo(() => isMatchPlayable(match), [match])

  const hasActiveAsset = useMemo(
    () =>
      filteredAssets.some(
        asset => mediaLocator?.url === asset.media_locator?.url
      ),
    [filteredAssets, mediaLocator]
  )

  const handleCollapse = useCallback((e: React.MouseEvent<{}, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setInternalCollapsed(prev => !prev)
  }, [])

  const handlePlay = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const target = event.target as HTMLDivElement
      const isBackdrop = target.classList.contains('MuiBackdrop-root')

      if (isBackdrop) return

      const id = playerId ?? ''
      dispatch(setMediaItem(createSetMatchPayload(id, match, 'pano')))
    },
    [match, dispatch, playerId]
  )

  const handleOnDownloadListOpen = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation()
    setDownloadAssetListAnchor(event.currentTarget)
  }

  const handleDownloadLocator = (
    event: React.MouseEvent<HTMLLIElement>,
    url: string
  ) => {
    event.stopPropagation()
    setDownloadAssetListAnchor(null)

    window.open(url, '_blank')
  }

  const teamNames = teams?.map(({ name }, index) => (
    <>
      {name} {index !== teams.length - 1 && <Vs />}
    </>
  ))

  const matchTitle = (
    <TitleRows>
      <TitleRow>
        <TextHoverScroll element={Title}>
          {useMatchTitle ? match.title : teamNames}
        </TextHoverScroll>
        <ResourceControls
          onDownload={
            downloadableMatchLocators.length
              ? handleOnDownloadListOpen
              : undefined
          }
        />
        {isLive && <LiveIndicator />}
        {isUpcoming && <UpcomingIcon />}
      </TitleRow>
      {useMatchTitle && (
        <TitleRow>
          <TextHoverScroll element={TitleSecondary}>
            {teamNames}
          </TextHoverScroll>
        </TitleRow>
      )}
      <Popover
        open={!!downloadAssetListAnchor}
        anchorEl={downloadAssetListAnchor}
        onClose={() => setDownloadAssetListAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <DownloadAssetList>
          {downloadableMatchLocators.map(({ type, url }) => {
            const matchAsset = MATCH_LOCATOR_TYPE.find(
              ({ view }) => view === type
            )!

            return (
              <MenuItem
                icon={matchAsset.icon}
                onClick={e => handleDownloadLocator(e, url)}
              >
                {matchAsset.displayName}
              </MenuItem>
            )
          })}
        </DownloadAssetList>
      </Popover>
    </TitleRows>
  )

  return (
    <>
      <Root active={active || hasActiveAsset} ref={matchRef}>
        <MatchInfoWrapper disabled={!isPlayable}>
          {/* <MatchInfoItem height={110}>
            <MatchImageWrapper
              onClick={hasMatchAnyTypes ? handlePlay : undefined}
              clickable={hasMatchAnyTypes}
            >
              <Play />
              <OverComponentSkeleton
                hidden={isImageLoaded}
                backgroundColor={theme.palette.primary.light}
                full
              />
              <MatchPreviewImage
                src={thumbnail ?? ClipPreviewImageSrc}
                onLoad={() => setIsImageLoaded(true)}
                alt='Clip'
              />
            </MatchImageWrapper>
          </MatchInfoItem> */}
          <MatchInfoItem
            onClick={isPlayable ? handlePlay : undefined}
            clickable={isPlayable}
          >
            {matchTitle}
            <MatchDescriptionWrapper>
              <div>
                <MatchDescriptionItem>
                  {t('time:date')}:{' '}
                  {format(new Date(+event_start * 1000), 'dd/MM/yy | hh:mm a')}
                </MatchDescriptionItem>
                <MatchDescriptionItem>
                  {t('time:duration')}:{' '}
                  {convertSecondsToTime(
                    differenceInSeconds(
                      new Date(+event_end * 1000),
                      new Date(+event_start * 1000)
                    )
                  )}
                </MatchDescriptionItem>
              </div>
              {filteredAssets && filteredAssets.length !== 0 && (
                <CollapseControlsWrapper>
                  <IconWrapper>
                    <AnimatedChevronIcon
                      name='chevron-up'
                      onClick={handleCollapse}
                      chevronUp={internalCollapsed}
                    />
                  </IconWrapper>
                </CollapseControlsWrapper>
              )}
            </MatchDescriptionWrapper>
          </MatchInfoItem>
        </MatchInfoWrapper>
      </Root>
      {filteredAssets && (
        <Collapse in={!internalCollapsed}>
          {filteredAssets.map(asset => (
            <ClipPreview
              key={asset.id}
              active={mediaLocator?.url === asset.media_locator?.url}
              clip={asset}
            />
          ))}
        </Collapse>
      )}
    </>
  )
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  DisplayIcon,
  DisplayItem,
  DisplayItemText,
  DisplayMenu,
} from './UserInviteList.styled'
import { InviteContextMenuProps } from './UserInviteList.interface'

export const UserInviteContextMenu: FC<InviteContextMenuProps> = ({
  id,
  open,
  anchorEl,
  onClose,
  onInviteDelete,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'admin' })
  const handleOnInviteDelete = () => {
    onInviteDelete(id)
    onClose()
  }
  return (
    <DisplayMenu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <DisplayItem onClick={handleOnInviteDelete}>
        <DisplayIcon name='delete' />
        <DisplayItemText>{t('button.delete')}</DisplayItemText>
      </DisplayItem>
    </DisplayMenu>
  )
}

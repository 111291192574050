import { useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
// Hooks
import { useUser } from 'modules/user/hooks'
import {
  useAdminOrganizationDelete,
  useOrganizationContext,
} from 'modules/organization/hooks'
// Components
import { ConfirmDialog, ConfirmDialogState } from 'modules/core/components'
// Utils
import { doesUserHaveRole } from 'modules/user/utils'
// Constants
import { USER_ROLE } from 'modules/user/constants'

import { OrganizationAdminEditProps } from './OrganizationAdminEdit.interface'

import {
  FormContainer,
  Header,
  Title,
  Label,
  InputWrapper,
  FullWidthFormControl,
  Row,
  EditContainer,
  DeleteButton,
  Spacer,
} from './OrganizationAdminEdit.styled'
import { RelationEdit } from 'modules/admin/components'
import { useNavigate } from 'react-router-dom'

export const OrganizationAdminEdit: FC<OrganizationAdminEditProps> = ({
  organization,
}) => {
  const { t } = useTranslation(['pages', 'userRole'])
  const navigate = useNavigate()

  const { activeOrganizationId } = useOrganizationContext()

  const adminUser = useUser()
  const deleteOrganization = useAdminOrganizationDelete()

  const [isDeleteOrganizationModalOpen, setIsDeleteOrganizationModalOpen] =
    useState(false)

  const isRoot = doesUserHaveRole(adminUser.data, [USER_ROLE.ROOT])

  const handleDeleteOrganization = (state: ConfirmDialogState) => {
    if (!isRoot || !organization) return

    const isSubmitted = state === ConfirmDialogState.SUBMITTED

    if (!isSubmitted) {
      setIsDeleteOrganizationModalOpen(false)
      return
    }

    if (isSubmitted) {
      deleteOrganization.mutate(organization?.id, {
        onSuccess: () => navigate('/admin/organizations'),
        onError: console.error,
      })
    }
  }

  if (!adminUser.isFetched || !organization)
    return (
      <Header>
        <Title>{t('pages:profile.form.profile.update.disabled')}</Title>
      </Header>
    )

  return (
    <>
      <ConfirmDialog
        open={isDeleteOrganizationModalOpen}
        onResult={handleDeleteOrganization}
        options={{
          title: t('admin.button.deleteOrganization'),
          description: t('admin.modal.deleteOrganization.description', {
            organizationName: organization.name,
          }),
        }}
      />
      <div>
        <FormContainer>
          <EditContainer>
            <Row>
              <InputWrapper>
                <Label>
                  {t('pages:profile.form.profile.update.association')}
                </Label>
                <FullWidthFormControl>
                  <RelationEdit
                    entity='organization'
                    resource='association'
                    entityId={organization.id}
                  />
                </FullWidthFormControl>
              </InputWrapper>
              <InputWrapper>
                <Label>
                  {t('pages:profile.form.profile.update.competition')}
                </Label>
                <FullWidthFormControl>
                  <RelationEdit
                    entity='organization'
                    resource='competition'
                    entityId={organization.id}
                  />
                </FullWidthFormControl>
              </InputWrapper>
            </Row>
            <Row>
              <InputWrapper>
                <Label>{t('pages:profile.form.profile.update.club')}</Label>
                <FullWidthFormControl>
                  <RelationEdit
                    entity='organization'
                    resource='club'
                    entityId={organization.id}
                  />
                </FullWidthFormControl>
              </InputWrapper>
              <InputWrapper>
                <Label>{t('pages:profile.form.profile.update.team')}</Label>
                <FullWidthFormControl>
                  <RelationEdit
                    entity='organization'
                    resource='team'
                    entityId={organization.id}
                  />
                </FullWidthFormControl>
              </InputWrapper>
            </Row>

            <Row>
              <InputWrapper>
                <Label>Camera</Label>
                <FullWidthFormControl>
                  <RelationEdit
                    entity='organization'
                    resource='camera'
                    entityId={organization.id}
                  />
                </FullWidthFormControl>
              </InputWrapper>
            </Row>

            {isRoot && (
              <Row>
                <div>
                  <Spacer />
                  <DeleteButton
                    text={t('admin.button.deleteOrganization')}
                    icon='danger-circle'
                    onClick={() => setIsDeleteOrganizationModalOpen(true)}
                    disabled={organization.id === activeOrganizationId}
                  />
                </div>
              </Row>
            )}
          </EditContainer>
        </FormContainer>
      </div>
    </>
  )
}

import { useMemo, useContext, FC } from 'react'
// Hooks
import { useReduxSelector } from 'modules/core/hooks'
import { useAnalyserPlayer } from 'modules/analyser/hooks'
import { useUserCache } from 'modules/cache/hooks'
import { useAssetList } from 'modules/asset/hooks'
import { useMatch, useMatchHighlights } from 'modules/match/hooks'
import { useEventDictionary } from 'modules/event/hooks'
// Context
import { ComponentInfoContext } from 'modules/generic/context'
import { VideoPlayerContext } from 'modules/video-player/context'
// Redux
import { selectMatchType, selectMediaLocator } from 'modules/video-player/redux'
// Components
import { Collapse } from '@mui/material'
import { Icon } from 'modules/core/components'
import { PlayTrack } from 'modules/video-player/components'
// Constants
import { EVENT_TYPES } from 'modules/event/constants/eventTypes'
// Types
import { Event } from '@sporttotal-tv/dip-coaching-client'
import { EventType } from 'modules/event/types'
import { TimelineEvent } from 'modules/video-player-controller/types'

import { EventsTimeline } from '../'
import {
  EventInfoWrapper,
  RowTitleWrapper,
  RowWrapper,
  Root,
} from './EventsOverview.styled'
import { EventControlViewProps } from './EventsOverview.interface'
import { highlightToTimelineEvent } from './EventsOverview.utils'

export const EventsOverview: FC<EventControlViewProps> = ({ isActive }) => {
  const { playerId } = useContext(VideoPlayerContext)
  const player = useAnalyserPlayer()

  const { componentCacheKey } = useContext(ComponentInfoContext)

  const { data } = useUserCache<{
    [key: string]: { visibleTimelines: EventType[] }
  }>({
    keys: [componentCacheKey],
  })

  const { match } = useMatch()
  const { aiHighlights, highlights } = useMatchHighlights({
    is_official: false,
  })
  const { highlights: officialHighlights } = useMatchHighlights({
    is_official: true,
  })
  const matchType = useReduxSelector(state =>
    selectMatchType(state, playerId ?? '')
  )

  const activeMediaLocator = useReduxSelector(state =>
    selectMediaLocator(state, playerId ?? '')
  )

  const activeMatchAsset = useMemo(() => {
    if (!activeMediaLocator || !match) return undefined

    return match.match_assets?.find(
      asset => asset.media_locator.id === activeMediaLocator.id
    )
  }, [match, activeMediaLocator])

  const assets = useAssetList(
    {
      match_asset_id: activeMatchAsset?.id,
      expand: ['event'],
      page_limit: 200,
    },
    {
      enabled: activeMatchAsset?.id !== undefined,
    }
  )

  const { eventIdDictionary, eventIndexDictionary } = useEventDictionary()

  // Mapped data
  const timelineHighlights = useMemo(
    () =>
      //   Highlights are in ms
      aiHighlights.map<TimelineEvent>(highlight => ({
        startTime: highlight.exactStartMs / 1000,
        duration: highlight.durationMs / 1000,
        event: eventIndexDictionary[highlight.type],
      })),
    [aiHighlights, eventIndexDictionary]
  )

  const timelineAssets = useMemo(() => {
    const assetsData = assets.data?.results || []

    return assetsData.map<TimelineEvent>(asset => ({
      startTime: asset.start_time,
      duration: asset.duration,
      event: asset.event as unknown as Event,
    }))
  }, [assets.data?.results])

  const officialTimelineHighlights = useMemo(
    () =>
      officialHighlights.map<TimelineEvent>(highlight =>
        highlightToTimelineEvent(
          highlight,
          eventIdDictionary[highlight.event_id]
        )
      ),
    [officialHighlights, eventIdDictionary]
  )

  const userTimelineHighlights = useMemo(
    () =>
      highlights.map<TimelineEvent>(highlight =>
        highlightToTimelineEvent(
          highlight,
          eventIdDictionary[highlight.event_id]
        )
      ),
    [highlights, eventIdDictionary]
  )

  const timelineEventData: Record<EventType, TimelineEvent[]> = {
    AI: timelineHighlights,
    ASSET: timelineAssets,
    OFFICIAL_HIGHLIGHTS: officialTimelineHighlights,
    USER_HIGHLIGHTS: userTimelineHighlights,
  }

  const visibleTimelinesCache =
    data?.[componentCacheKey]?.visibleTimelines ?? []

  return (
    <Root>
      <RowWrapper>
        <RowTitleWrapper>
          <Icon name='time' />
        </RowTitleWrapper>
        <EventInfoWrapper>
          <PlayTrack player={player} disabled={!activeMediaLocator} />
        </EventInfoWrapper>
      </RowWrapper>
      <Collapse in={isActive && !!matchType}>
        {Object.values(EVENT_TYPES).map(timeline => (
          <Collapse
            key={timeline}
            in={visibleTimelinesCache.includes(timeline)}
          >
            <EventsTimeline
              type={timeline}
              data={timelineEventData[timeline]}
            />
          </Collapse>
        ))}
      </Collapse>
    </Root>
  )
}

import { TabDto } from 'modules/core/components/AppBar/tab.types'
import { generateId } from 'modules/core/helpers/generateId'
import { UserFeatureTier } from 'modules/user/types'

const ANALYZER_LITE_DEFAULT_TABS: TabDto[] = [
  { id: generateId('v1'), name: 'Dashboard', static: true, path: 'dashboard' },
  { id: generateId('v1'), name: 'Ticker', static: false, path: 'ticker' },
  {
    id: generateId('v1'),
    name: 'Comparison',
    static: false,
    path: 'comparison',
  },
]

const ANALYZER_DEFAULT_TABS: TabDto[] = [
  { id: generateId('v1'), name: 'Dashboard', static: true, path: 'dashboard' },
  { id: generateId('v1'), name: 'Clipping', static: false, path: 'clipping' },
  {
    id: generateId('v1'),
    name: 'Telestration',
    static: false,
    path: 'telestration',
  },
  {
    id: generateId('v1'),
    name: 'Comparison',
    static: false,
    path: 'comparison',
  },
]

export const ANALYZER_DEFAULT_TIER_TABS: Record<UserFeatureTier, TabDto[]> = {
  lite: ANALYZER_LITE_DEFAULT_TABS,
  basic: ANALYZER_DEFAULT_TABS,
  advanced: ANALYZER_DEFAULT_TABS,
  professional: ANALYZER_DEFAULT_TABS,
}

import { useTranslation } from 'react-i18next'
import { FC, useCallback, useMemo } from 'react'
// hooks
import { useAdminUserUpdate } from 'modules/user/hooks'

import {
  DisplayIcon,
  DisplayItem,
  DisplayItemText,
  DisplayMenu,
} from './UserList.styled'
import { UserContextMenuProps } from './UserList.interface'
import { ButtonDeleteInvitation } from './ButtonDeleteInvitation'

export const UserContextMenu: FC<UserContextMenuProps> = ({
  user,
  open,
  onClose,
  anchorEl,
}) => {
  const { t } = useTranslation(['pages', 'userRole'])
  const adminUserUpdate = useAdminUserUpdate()

  const isUserActive = useMemo(() => {
    return user.status === 'ACTIVE'
  }, [user])
  const isUserPending = useMemo(() => {
    return user.status === 'PENDING'
  }, [user])

  const handleChangeUserStatus = useCallback(() => {
    adminUserUpdate.mutate({
      id: user.id,
      params: {
        status: isUserActive ? 'SUSPENDED' : 'ACTIVE',
      },
    })
    onClose()
  }, [isUserActive, user, adminUserUpdate, onClose])

  return (
    <DisplayMenu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {isUserPending && (
        <ButtonDeleteInvitation email={user.email} onClose={onClose} />
      )}
      <DisplayItem onClick={handleChangeUserStatus}>
        <DisplayIcon name={isUserActive ? 'suspend' : 'check-circle'} />
        <DisplayItemText>
          {t(
            isUserActive
              ? 'pages:admin.button.suspend'
              : 'pages:admin.button.activate'
          )}
        </DisplayItemText>
      </DisplayItem>
    </DisplayMenu>
  )
}
